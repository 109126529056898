import React from "react";
import Sidebar from "../../Include/Sidebar";
import { Link } from "react-router-dom";
import Footer from "../../Include/Footer";
import DataAgents from "../../data/DataAgents";

function ListAgent() {
  return (
    <>
      <Sidebar />

      <main className="app-main">
        <div className="app-content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">Gestion des Agents</h3>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right py-0 px-4">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Tableau de bord</Link>
                  </li>
                  <li className="breadcrumb-item">Agents</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="app-content">
          <div className="container-fluid">
            <div className="card p-4">
              <div className="row mb-4">
                <div className="col-10">
                  <h2 className="">Liste des Agents</h2>
                </div>
                <div className="col d-flex justify-content-end">
                  <Link
                    to="/add-agent"
                    className="btn btn-primary btn-simple"
                  >
                    <i className="fas fa-user-plus"></i>
                  </Link>
                </div>
              </div>
              <DataAgents />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ListAgent;
