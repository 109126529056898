import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Include/Sidebar";
import { Link } from "react-router-dom";
import Footer from "../../Include/Footer";
import "../accounts/form.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { AccountContext } from "../../context/AccountsContext";
import AuthContext from "../../context/AuthContext";

function AddAgent() {
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState(1);
  const [clientId, setClientId] = useState(null);
  const [clientData, setClientData] = useState({});
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const {
    createClient,
    createAgent,
    CancelClient,
    ValidateClient,
  } = useContext(AccountContext);

  const TYPE_CHOICES = [
    { value: 'Agent', label: 'Agent' },
    { value: 'SuperAgent', label: 'SuperAgent' },
  ];

  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    tel: "",
    sexe: "",
    username: "",
    password: "",
    type_compte: "",
    devise: "",
    nb_main: "",
    sol: "",
    frequence_paiement: "",
    duree_sol: "",
    date_debut_sol: "",
  });

  const calculateProgress = () => {
    const fieldsFilled = Object.keys(formData).filter(
      (key) => formData[key] && formData[key] !== ""
    ).length;
    const totalFields = Object.keys(formData).length;
    return Math.round((fieldsFilled / totalFields) * 100);
  };

  const progressPercentage = calculateProgress();
  useEffect(() => {
    if (clientId && step === 2) {
      //console.log("Client ID prêt à être utilisé :", clientId);
      // Votre logique pour l'étape 2
    }
  }, [clientId, step]);

  const validateStep = async () => {
    const newErrors = {};
    let id = "";
    if (step === 1) {
      // Validations pour l'étape 1
      if (!formData.nom) {
        newErrors.nom = "Le champ nom est obligatoire.";
      }
      if (!formData.prenom) {
        newErrors.prenom = "Le champ prénom est obligatoire.";
      }
      if (!formData.tel) {
        newErrors.tel = "Le numéro de téléphone est obligatoire.";
      } else if (!/^\+?[1-9]\d{1,14}$/.test(formData.tel)) {
        newErrors.tel = "Le numéro de téléphone n'est pas valide.";
      }
      if (!formData.sexe) {
        newErrors.sexe = "Le champ sexe est obligatoire.";
      }
      if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = "L'email n'est pas valide.";
      }

      if (Object.keys(newErrors).length === 0) {
        const {
          nom,
          prenom,
          tel,
          email,
          date_naissance,
          lieu_naissance,
          sexe,
          adresse,
        } = formData;

        let formData1 = new FormData();
        formData1.append("nom", nom);
        formData1.append("prenom", prenom);
        formData1.append("email", email);
        formData1.append("tel", tel);
        formData1.append("date_naissance", date_naissance);
        formData1.append("lieu_naissance", lieu_naissance);
        formData1.append("sexe", sexe);
        formData1.append("adresse", adresse);
        formData1.append("created_by", user.user_id);

        try {
          setLoading(true);
          const response = await createClient(formData1);
          if (response.success) {
            setClientId(response.data.id); // Stockez l'ID client directement
            setStep(step + 1);
          } else {
            Object.keys(response.error).forEach((key) => {
              newErrors[key] = response.error[key][0];
            });
          }
        } catch (error) {
          newErrors.general = "Erreur lors de l'enregistrement.";
        } finally {
          setLoading(false);
        }
      } else {
        setErrors(newErrors);
      }
    } else if (step === 2) {
      if (!clientId) {
        console.error("L'ID client est requis pour l'étape 2.");
        return;
      }
      if (!formData.type_compte)
        newErrors.type_compte = "Le type de compte est requis.";
      if (!formData.devise) newErrors.devise = "La devise est requise.";

      if (Object.keys(newErrors).length === 0) {
        try {
          const {
            type_compte,
            devise,
          } = formData;

          setLoading(true); // Activer le loader
          let formData2 = new FormData();
          formData2.append("client", clientId); // Utilisez l'ID client ici
          formData2.append("type_compte", type_compte);
          formData2.append("devise", devise);
          formData2.append("created_by", user.user_id);
          formData2.append("email", clientData.email);
          formData2.append("username", clientData.username);
          formData2.append("password", clientData.pass_send);

          const response = await createAgent(formData2);
          console.log("response client ID");

          await ValidateClient(clientId);
          if (response.id) {
            // Réinitialiser les données et revenir à l'étape 1
            // setFormData({});
            // setStep(1);
          } else {
            newErrors.general = "Erreur lors de la création du compte.";
          }
        } catch (error) {
          newErrors.general = "Erreur réseau ou serveur.";
        } finally {
          setLoading(false);
        }
      } else {
        setErrors(newErrors);
      }
    }

    setErrors(newErrors);
    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Étape suivante
  const handleNext = async () => {
    // Attendez que la validation soit terminée
    const currentErrors = await validateStep();

    if (Object.keys(currentErrors).length === 0) {
      setStep(step + 1);
    }
  };

  // Étape précédente
  const handlePrevious = () => {
    setStep(step - 1);
  };

  // Annuler l'opération
  const handleCancel = async () => {
    if (clientId) {
      try {
        await CancelClient(clientId);
      } catch (error) {
        console.error("Erreur lors de l'annulation :", error);
      }
    }
    // Réinitialiser les données et revenir à l'étape 1
    setFormData({});
    setStep(1);
  };

  return (
    <>
      <Sidebar />
      <main className="app-main">
        <div className="app-content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">Ajouter un Agent</h3>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Tableau de bord</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/list-agent">Agents</Link>
                  </li>
                  <li className="breadcrumb-item active">Ajouter</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="app-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h3 className="card-title">Ajouter un Agent</h3>
                  </div>
                  <div className="card-body">
                    <div className="progress mb-4">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${progressPercentage}%` }}
                        aria-valuenow={progressPercentage}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {progressPercentage}%
                      </div>
                    </div>


                    {step === 1 && (
                      <div className="step-content">
                        <h4 className="mb-4">Informations personnelles</h4>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label htmlFor="nom" className="form-label">
                              Nom <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${errors.nom ? "is-invalid" : ""
                                }`}
                              id="nom"
                              name="nom"
                              value={formData.nom}
                              onChange={handleChange}
                              placeholder="Entrez le nom"
                            />
                            {errors.nom && (
                              <div className="invalid-feedback">{errors.nom}</div>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="prenom" className="form-label">
                              Prénom <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${errors.prenom ? "is-invalid" : ""
                                }`}
                              id="prenom"
                              name="prenom"
                              value={formData.prenom}
                              onChange={handleChange}
                              placeholder="Entrez le prénom"
                            />
                            {errors.prenom && (
                              <div className="invalid-feedback">{errors.prenom}</div>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <input
                              type="email"
                              className={`form-control ${errors.email ? "is-invalid" : ""
                                }`}
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              placeholder="Entrez l'email"
                            />
                            {errors.email && (
                              <div className="invalid-feedback">{errors.email}</div>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="tel" className="form-label">
                              Téléphone <span className="text-danger">*</span>
                            </label>
                            <PhoneInput
                              country={"ht"}
                              value={formData.tel}
                              onChange={(phone) =>
                                setFormData({ ...formData, tel: phone })
                              }
                              inputClass={`form-control ${errors.tel ? "is-invalid" : ""
                                }`}
                              containerClass="phone-input-container"
                              inputProps={{
                                name: "tel",
                                id: "tel",
                                required: true,
                              }}
                            />
                            {errors.tel && (
                              <div className="invalid-feedback d-block">
                                {errors.tel}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label htmlFor="sexe" className="form-label">
                              Sexe <span className="text-danger">*</span>
                            </label>
                            <select
                              className={`form-control ${errors.sexe ? "is-invalid" : ""
                                }`}
                              id="sexe"
                              name="sexe"
                              value={formData.sexe}
                              onChange={handleChange}
                            >
                              <option value="">Sélectionnez le sexe</option>
                              <option value="M">Masculin</option>
                              <option value="F">Féminin</option>
                            </select>
                            {errors.sexe && (
                              <div className="invalid-feedback">{errors.sexe}</div>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="date_naissance" className="form-label">
                              Date de naissance
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              id="date_naissance"
                              name="date_naissance"
                              value={formData.date_naissance || ""}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label htmlFor="lieu_naissance" className="form-label">
                              Lieu de naissance
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="lieu_naissance"
                              name="lieu_naissance"
                              value={formData.lieu_naissance || ""}
                              onChange={handleChange}
                              placeholder="Entrez le lieu de naissance"
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="adresse" className="form-label">
                              Adresse
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="adresse"
                              name="adresse"
                              value={formData.adresse || ""}
                              onChange={handleChange}
                              placeholder="Entrez l'adresse"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {step === 2 && (
                      <div className="step-content">
                        <h4 className="mb-4">Type de compte et devise</h4>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label htmlFor="type_compte" className="form-label">
                              Type de compte <span className="text-danger">*</span>
                            </label>
                            <select
                              className={`form-control ${errors.type_compte ? "is-invalid" : ""
                                }`}
                              id="type_compte"
                              name="type_compte"
                              value={formData.type_compte}
                              onChange={handleChange}
                            >
                              <option value="">Sélectionnez le type de compte</option>
                              {TYPE_CHOICES.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            {errors.type_compte && (
                              <div className="invalid-feedback">
                                {errors.type_compte}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="devise" className="form-label">
                              Devise <span className="text-danger">*</span>
                            </label>
                            <select
                              className={`form-control ${errors.devise ? "is-invalid" : ""
                                }`}
                              id="devise"
                              name="devise"
                              value={formData.devise}
                              onChange={handleChange}
                            >
                              <option value="">Sélectionnez la devise</option>
                              <option value="HTG">HTG</option>
                              <option value="USD">USD</option>
                            </select>
                            {errors.devise && (
                              <div className="invalid-feedback">{errors.devise}</div>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label htmlFor="sol" className="form-label">
                              Solde initial
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="sol"
                              name="sol"
                              value={formData.sol || ""}
                              onChange={handleChange}
                              placeholder="Entrez le solde initial"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="d-flex justify-content-between mt-4">
                      {step > 1 && (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={handlePrevious}
                        >
                          Précédent
                        </button>
                      )}
                      <div>
                        <button
                          type="button"
                          className="btn btn-danger me-2"
                          onClick={handleCancel}
                        >
                          Annuler
                        </button>
                        {step < 2 ? (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleNext}
                            disabled={loading}
                          >
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Suivant"
                            )}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={validateStep}
                            disabled={loading}
                          >
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Enregistrer"
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AddAgent;
