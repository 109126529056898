/* eslint-disable */
// import './Side.css'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { jwtDecode } from 'jwt-decode'
// import AuthContext from '../context/AuthContext'
import { format } from "date-fns";
import { fr } from 'date-fns/locale';
import Logo from './logo1.png'
import { BaseUrlPhoto } from '../context/BaseUrl';
import { AccountContext } from '../context/AccountsContext';
const BaseUrl = BaseUrlPhoto;


function Sidebar() {
  const { logoutUser } = useContext(AuthContext)
  const { getAccountByTel } = useContext(AccountContext)
  const token = localStorage.getItem("authTokens")
  const [user, setUser] = useState(null);
  // Utilisez useState pour stocker la date et l'heure de la connexion
  const [loginTime, setLoginTime] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false); // Ajout de l'état dropdownOpen
  const currentYear = new Date().getFullYear();
  // Calculer l'année académique en fonction de l'année actuelle
  const academicYear = `${currentYear}-${currentYear + 1}`;

  if (token) {
    const decoded = jwtDecode(token)
    var user_id = decoded.user_id
    var username = decoded.username
    var role = decoded.role
    var image = decoded.image
  }

  const getUserFromLocalStorage = () => {
    if (token) {
      return JSON.parse(token).user;
    }
    return null;
  };

  useEffect(() => {
    const userData = getUserFromLocalStorage();
    if (userData) {
      setUser(userData);
    }
  }, []);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      const loginDate = new Date(decoded.iat * 1000);
      const formattedDate = format(loginDate, "EE dd MMMM HH:mm", { locale: fr });

      const parts = formattedDate.split(' ');
      const capitalizedDay = parts[0].charAt(0).toUpperCase() + parts[0].slice(1);
      const capitalizedMonth = parts[2].charAt(0).toUpperCase() + parts[2].slice(1);

      const finalFormattedDate = `${capitalizedDay} ${parts[1]} ${capitalizedMonth} | ${parts[3]}`;

      setLoginTime(finalFormattedDate);
    }
  }, [token]);

  const imageUrl = user && user.image ? `${BaseUrl}${user.image}` : '';

  const [MenuOpen4, setMenuOpen4] = useState(false);
  const toggleMenu4 = () => {
    setMenuOpen4(!MenuOpen3);
  };

  const [MenuOpen3, setMenuOpen3] = useState(false);

  const toggleMenu3 = () => {
    setMenuOpen3(!MenuOpen3);
  };
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    document.body.classList.toggle('sidebar-open', !isOpen);
    document.body.classList.toggle('sidebar-collapse', isOpen);
  };

  return (
    <>

      <nav className="app-header navbar navbar-expand bg-body">
        <div className="container-fluid">

          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                onClick={toggleSidebar}
                className="nav-link text-muted fs-5"
                data-widget="pushmenu"
                href="#"
                role="button"
              >
                <i className="fas fa-bars"></i>
              </a>
            </li>
          </ul>


          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link text-muted" href="#" data-lte-toggle="fullscreen">
                <i data-lte-icon="maximize" className="bi bi-arrows-fullscreen"></i>
                <i data-lte-icon="minimize" className="bi bi-fullscreen-exit" style={{ display: "none" }}></i>
              </a>
            </li>

            <li className="nav-item dropdown user-menu">
              <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                {user && (
                  <img
                    src={imageUrl}
                    alt="User Avatar"
                    className="user-image rounded-circle shadow"
                  />
                )}
              </a>
              <ul className="dropdown-menu dropdown-menu-lg dropdown-menu-end">
                <li className="user-header text-bg-primary">
                  <img src={imageUrl} className="rounded-circle shadow" alt="User" />
                  <p>
                    {user && user.username}
                    <small>{user && user.role}</small>
                  </p>
                </li>
                <li className="user-body">
                  <p className="text-sm text-success text-center">Connecté le :</p>
                  <p className="text-sm text-muted text-center">
                    <i className="far fa-clock mr-1"></i> {loginTime}
                  </p>
                </li>
                <li className="user-footer">
                  <Link to={`/user-profile/${user_id}`} className="btn btn-default btn-flat">
                    Mon Profil
                  </Link>
                  <a href="#" className="btn btn-default btn-flat float-end" onClick={logoutUser}>
                    Déconnecter
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
      <aside className={`app-sidebar  bg-body-secondary elevation-4 ${isOpen ? 'sidebar-open' : 'sidebar-collapse'}`} data-bs-theme="dark">
        <div className="sidebar-brand">

          <Link to="/dashboard" className="brand-link">

            <img
              src={Logo}
              alt="Bousanm Logo"
              className="brand-image opacity-75 shadow"
            />

            <span className="brand-text fw-light">Bousanm</span>

          </Link>

        </div>

        <div className="sidebar-wrapper">
          <nav className="mt-2">

            <ul
              className="nav sidebar-menu flex-column"
              data-lte-toggle="treeview"
              role="menu"
              data-accordion="false"
            >
              <Link to="/dashboard" className="nav-link">
                <i className="nav-icon fas fa-tachometer-alt text-white"></i>
                <p className='fw-bold text-white'>
                  Tableau de bord
                </p>
              </Link>
              <li className="nav-header">GESTION DES COMPTES</li>
              <hr className='mb-0 mt-1' />
              <li className="nav-item">
                <Link to="/List" className="nav-link">
                  <i className="fas fa-user-cog nav-icon text-white"></i>
                  <p className='text-white'>Gestion Compte</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/list-agent" className="nav-link">
                  <i className="fas fa-users nav-icon text-white"></i>
                  <p className='text-white'>Gestion Agent</p>
                </Link>
              </li>
              <hr className='mb-0 mt-1' />
              <li className="nav-item">
                <Link to="/transaction" className="nav-link">
                  <i className="fas fa-exchange-alt nav-icon text-white"></i>
                  <p className='text-white'>Gestion Transactions </p>
                </Link>
              </li>
              <hr className='mb-0 mt-1' />
              <li className="nav-item">
                <Link to="/history" className="nav-link">
                  <i className="fas fa-exchange-alt nav-icon text-white"></i>
                  <p className='text-white'>Historique Transactions </p>
                </Link>
              </li>
              <hr className='mb-0 mt-1' />
              <li className="nav-item">
                <Link to="/excel-report" className="nav-link">
                  <i className="fas fa-file-excel nav-icon text-white"></i>
                  <p className='text-white'>Rapports Excel </p>
                </Link>
              </li>
              <hr className='mb-0 mt-1' />
              <li className="nav-item">
                <Link to="/paiement" className="nav-link">
                  <i className="fas fa-exchange-alt nav-icon text-white"></i>
                  <p className='text-white'>Paiements </p>
                </Link>
              </li>
              <hr className='mb-0 mt-1' />
              <li className="nav-item">
                <Link to="/validate-account" className="nav-link">
                  <i className="fas fa-exchange-alt nav-icon text-white"></i>
                  <p className='text-white'>Comptes non Validé </p>
                </Link>
              </li>
              <hr className='mb-0 mt-1' />
              <li className="nav-item">
                <Link to="/excel-report" className="nav-link">
                  <i className="fas fa-chart-bar nav-icon text-white"></i>
                  <p className='text-white'>Rapports </p>
                </Link>
              </li>
              <hr className='mb-0 mt-1' />
              <li className="nav-header fw-bold text-white">ADMINISTRATION</li>
              <li className={`nav-item ${MenuOpen4 ? 'menu-open' : ''}`}>
                <a href="#" className="nav-link" onClick={toggleMenu4}>
                  <i className="nav-icon fas fa-cog text-white"></i>
                  <p className='text-white'>Paramètres <i className="fas fa-angle-left right text-white"></i></p>
                </a>
                <ul className={`nav nav-treeview ${MenuOpen4 ? 'slide-in' : 'slide-out'}`}>
                  <li className="nav-item">
                    <Link to="/users" className="nav-link">
                      <i className="fas fa-user-plus nav-icon text-white"></i>
                      <p className='text-white'>Gestion Utilisateurs</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/" className="nav-link">
                      <i className="fas fa-user-plus nav-icon text-white"></i>
                      <p className='text-white'>Gestion Sol</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link to="/report" className="nav-link">
                  <i className="nav-icon fas fa-file-alt text-white"></i>
                  <p className='text-white'>Rapport</p>
                </Link>
              </li>
            </ul>

          </nav>
        </div>

      </aside>
    </>
  )
}

export default Sidebar
