import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Include/Sidebar";
import { Link } from "react-router-dom";
import { TransactionsContext } from "../../context/TransactionsContext";
import AuthContext from "../../context/AuthContext";
import { AccountContext } from "../../context/AccountsContext";
const swal = require("sweetalert2");

function AddPay() {
  const { getAccountByCode, getAccounts } = useContext(AccountContext);
  const { VerifierTransaction, createPay } = useContext(TransactionsContext);

  const [dataAccount, setDataAccount] = useState(null); // Exemple de données du compte

  const [code, setcode] = useState({ code: "" });
  // const [dataAccount, setDataAccount] = useState();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleFilterAccounts = () => {
    const value = code.code;
    if (value === "") {
      swal.fire({
        title: "Entrer Numero du compte",
        icon: "warning",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    let formData = new FormData();
    formData.append("code", value);

    getAccountByCode(formData).then((data) => {
      if (data) {
        setDataAccount(data); // Mettez à jour records avec les données récupérées
      }
    });
  };

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const nb_main = e.target.nb_main.value;
  
    const newErrors = {};
  
    // Vérification locale des champs
    if (!dataAccount) {
      newErrors.compte = "Veuillez rechercher le compte.";
    }
    if (!nb_main) {
      newErrors.nb_main = "Veuillez entrer le nombre de main.";
    }
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    setErrors({}); // Réinitialiser les erreurs locales
  
    let formData = new FormData();
    formData.append("compte", dataAccount.id);
    formData.append("nb_main", nb_main);
    formData.append("created_by", user.user_id);
  
    try {
      setLoading(true); // Activer le loader
      const backendErrors = await createPay(formData);
  
      if (backendErrors) {
        // Vérifiez si des erreurs sont spécifiques à des champs
        if (backendErrors.nb_main) {
          newErrors.nb_main = backendErrors.nb_main.join(", "); // Concaténer les erreurs pour le champ nb_main
        }
        if (backendErrors.general) {
          newErrors.general = backendErrors.general;
        }
  
        setErrors(newErrors);
      }
    } finally {
      setLoading(false); // Désactiver le loader
    }
  };
  

  const [allAccount, setAllAccount] = useState([]);
  const [filteredAccount, setFilteredAccount] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAccounts(); // Appel direct à la méthode du contexte
        setAllAccount(data); // Mise à jour de l'état avec les données
        setFilteredAccount(data); // Initialement, tous les comptes sont affichés
      } catch (error) {
        console.error("Erreur lors de la récupération des comptes :", error);
      }
    };
    fetchData();
  }, [getAccounts]);

  const handleSearchChange = (text) => {
    setSearchText(text);

    // Filtrage par `code`, `nom` ou `prenom`
    const filtered = allAccount.filter((account) =>
      [account.code, account.client_nom, account.client_prenom].some((field) =>
        field?.toLowerCase().includes(text.toLowerCase())
      )
    );

    setFilteredAccount(filtered);
  };

  const handleSelectStudent = (e) => {
    const selectedCode1 = e.target.value;
    const selectedStudent = allAccount.find(
      (student) => student.code === selectedCode1
    );
    if (selectedStudent) {
      setDataAccount(selectedStudent);
    }
  };

  return (
      <>
        <Sidebar />

        <main className="app-main">
          <div className="app-content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="mb-0">Gestion des Transactions</h3>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right py-0 px-4">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Tablau de bord</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/transaction">Transactions</Link>
                    </li>
                    <li className="breadcrumb-item">Nouvelle Transaction</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="app-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-6 col-lg-5">
                  <div className="card">
                    <div className="card-body">
                      <div className="row g-2 mx-auto">
                        <div className="col-md-12">
                          <h3 className="h3">Recherche Compte</h3>
                        </div>
                        <div className="col-md-12">
                          <div className="input-group mb-1">
                            {/* Champ de recherche */}
                            <input
                              className={`form-control form-control-sm fw-9 ${
                                errors.compte ? "is-invalid" : ""
                              }`}
                              type="text"
                              id="search"
                              placeholder="Rechercher par Code"
                              value={searchText ? searchText : ""}
                              onChange={(e) =>
                                handleSearchChange(e.target.value)
                              }
                            />
                            {errors.compte && (
                              <div className="invalid-feedback">
                                {errors.compte}
                              </div>
                            )}

                            {/* Liste des comptes filtrés */}
                            <select
                              className={`form-control form-control-sm  p-0 ${
                                errors.compte ? "is-invalid" : ""
                              }`}
                              size="1" // Affiche plusieurs options en même temps
                              onChange={handleSelectStudent}
                            >
                              <option value={""} selected></option>
                              {filteredAccount.map((student) => (
                                <option key={student.code} value={student.code}>
                                  {student.code} - {student.client_nom}{" "}
                                  {student.client_prenom}
                                </option>
                              ))}
                            </select>
                            {errors.compte && (
                              <div className="invalid-feedback">
                                {errors.compte}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* Affichage des détails du compte sélectionné */}
                        <div className="col-md-6">
                          <input
                            type="text"
                            disabled
                            placeholder="Numero Compte"
                            value={dataAccount?.code || ""}
                            className="form-control form-control-sm"
                          />
                          <span className="text-muted small">Numéro de compte</span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            disabled
                            placeholder="Nom complet"
                            value={`${dataAccount?.client_nom || ""} ${
                              dataAccount?.client_prenom || ""
                            }`}
                            className="form-control form-control-sm"
                          />
                          <span className="text-muted small">Nom complet</span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            disabled
                            placeholder="Type Compte"
                            value={dataAccount?.type_compte || ""}
                            className="form-control form-control-sm"
                          />
                          <span className="text-muted small">Type de compte</span>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            disabled
                            placeholder="Etat du compte"
                            value={dataAccount?.etat || ""}
                            className="form-control form-control-sm"
                          />
                          <span className="text-muted small">État</span>
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            disabled
                            placeholder="Etat du compte"
                            value={dataAccount?.solde_BS || ""}
                            className="form-control form-control-sm"
                          />
                          <span className="text-muted small">BS</span>
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            disabled
                            placeholder="Etat du compte"
                            value={dataAccount?.solde_PL || ""}
                            className="form-control form-control-sm"
                          />
                          <span className="text-muted small">Plasman</span>
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            disabled
                            placeholder="Etat du compte"
                            value={dataAccount?.solde_S || ""}
                            className="form-control form-control-sm"
                          />
                          <span className="text-muted small">Sol</span>
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            disabled
                            placeholder="Etat du compte"
                            value={dataAccount?.solde_M || ""}
                            className="form-control form-control-sm"
                          />
                          <span className="text-muted small">Mutuelle</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Transactions */}
                {dataAccount?.etat !== "inactif" ? (
                  <div className="col-xl-6 col-lg-5">
                    <div className="card shadow mb-4">
                      <div className="card-body">
                        <form
                          className="row g-2 mx-auto"
                          onSubmit={handleSubmit}
                          encType="multipart/form-data"
                        >
                          <div className="col-md-12">
                            <h3 className="h3">Transaction</h3>
                          </div>
                          <div className="col-md-12">
                            <input
                              type="number"
                              className={`form-control  form-control-sm ${
                                errors.nb_main ? "is-invalid" : ""
                              }`}
                              name="nb_main"
                              placeholder="Montant"
                              id="montant"
                              aria-label="montant"
                              aria-describedby="basic-addon3"
                            />
                            {errors.nb_main && (
                              <div className="invalid-feedback">
                                {errors.nb_main}
                              </div>
                            )}
                          </div>
                          {/* end Placement input */}
                          <div className="col-md-12" />
                          <div className="col-md-12">
                            <div className="input-group">
                              {errors.general && (
                                <div className="alert alert-danger mt-3">
                                  {errors.general}
                                </div>
                              )}

                              <button
                                style={{ width: "100%" }}
                                type="submit"
                                className="btn btn-primary btn-sm"
                                disabled={loading}
                              >
                                {loading ? (
                                  <>
                                    <i className="fas fa-spinner fa-spin"></i>{" "}
                                    Validation en cours...
                                  </>
                                ) : (
                                  <>Valider</>
                                )}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-xl-6 col-lg-5">
                    <div className="card shadow mb-4">
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Transaction sur le compte
                        </h6>
                      </div>
                      <div className="card-body">
                        <p className="text-danger">
                          Le compte est inactif. Les transactions sont
                          désactivées.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {/*End Transactions */}
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </main>
      </>
  );
}

export default AddPay;
