import React, { createContext, useContext, useState } from "react";
import AuthContext from "./AuthContext";
import { useHistory } from "react-router-dom";
import { baseUrlTransactions } from './BaseUrl';
const swal = require("sweetalert2");
const baseUrl = baseUrlTransactions;

const TransactionsContext = createContext();

const TransactionProvider = ({ children }) => {
    const [loading1, setLoading] = useState(false);
  const { authTokens,fetchWithAuth } = useContext(AuthContext);

  const history = useHistory();
  
  const getTransactions = async (url = `${baseUrl}?page=1&page_size=10`) => {
    const response = await fetchWithAuth(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  };
  
  const getPaiement = async () => {
     const response = await fetchWithAuth(`${baseUrl}transactions/list-paiement`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };
  const getTransactionById = async (TransactionId) => {
     const response = await fetchWithAuth(`${baseUrl}${TransactionId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  const getTransactionByCode = async (formData) => {
     const response = await fetchWithAuth(`${baseUrl}get-Transaction-by-code/`, {
      method: "POST",
      body: formData,
    });

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 404) {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "Not Found " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      console.log("there was a server issue");
    }
  };

  const VerifierTransaction = async (formData) => {
     const response = await fetchWithAuth(`${baseUrl}verifier-transaction/`, {
      method: "POST",
      body: formData,
    });

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 404) {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "Not Found " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      console.log("there was a server issue");
    }
  };

  const deleteTransactions = async (TransactionId) => {
     const response = await fetchWithAuth(`${baseUrl}${TransactionId}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 204) {
      swal.fire({
        title: "User Deleted Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const updateTransactions = async (TransactionId, formData) => {
     const response = await fetchWithAuth(`${baseUrl}${TransactionId}/`, {
      method: "PUT",
      body: formData,
    });
    if (response.status === 200) {
      swal.fire({
        title: "Transaction Updated Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      history.push("/List");
    } else {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status + "\n" + response.error,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const createTransactionPL = async (formData) => {
    return await createTransaction("create-pl/", formData);
  };
  
  const createTransactionBS = async (formData) => {
    return await createTransaction("create-bs/", formData);
  };
  
  const createTransactionMu = async (formData) => {
    return await createTransaction("create-mu/", formData);
  };
  
  const createTransactionSol = async (formData) => {
    return await createTransaction("create-sol/", formData);
  };
  
  const createTransaction = async (url, formData) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}${url}`, {
        method: "POST",
        body: formData,
      });
  
      if (response.status === 201) {
        swal.fire({
          title: "Transaction Created Successfully",
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        history.push("/transaction");
        return null; // Aucun message d'erreur si succès
      } else if (response.status >= 400 && response.status < 500) {
        const errorData = await response.json(); // Parse les erreurs du backend
        return errorData; // Retourne les erreurs spécifiques
      } else {
        return { general: "Une erreur serveur inattendue s'est produite." };
      }
    } catch (error) {
      console.error("Erreur réseau :", error);
      return { general: "Erreur de connexion au serveur. Veuillez réessayer." };
    }
  };
  
  const createPay = async (formData) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}transactions/paiement`, {
        method: "POST",
        body: formData,
      });
  
      if (response.status === 201) {
        swal.fire({
          title: "Transaction Created Successfully",
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        history.push("/paiement");
        return null; // Aucun message d'erreur si succès
      } else if (response.status >= 400 && response.status < 500) {
        const errorData = await response.json(); // Parse JSON contenant les erreurs
        return errorData; // Retourner directement les erreurs du backend
      } else {
        return { general: "Une erreur serveur inattendue s'est produite." };
      }
    } catch (error) {
      console.error("Erreur réseau :", error);
      return { general: "Erreur de connexion au serveur. Veuillez réessayer." };
    }
  };

  
  const near_end_date = async () => {
    try {
       const response = await fetchWithAuth(`${baseUrl}near_end_date/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        if (response.status === 403) {
          swal.fire({
            title:
              "Permission Denied: \nYou cannot deactivate your own Transaction.",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          throw new Error("Failed to activate/deactivate user");
        }
      } else {
        const data = await response.json();
        return data
      }
    } catch (error) {
      console.error(error);
      swal.fire({
        title: "Failed to activate/deactivate user",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const calculate_interest = async () => {
    try {
       const response = await fetchWithAuth(`${baseUrl}calculate-interest/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        if (response.status === 403) {
          swal.fire({
            title:
              "Permission Denied: \nYou cannot deactivate your own Transaction.",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          throw new Error("Failed to activate/deactivate user");
        }
      } else {
        history.push("/List");
        const data = await response.json();
        swal.fire({
          title: data.message,
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error(error);
      swal.fire({
        title: "Failed to activate/deactivate user",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const renewPlasman = async (account_id, formData) => {
    try {
       const response = await fetchWithAuth(`${baseUrl}renew-plasman/${account_id}`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        if (response.status === 403) {
          swal.fire({
            title:
              "Permission Denied: \nYou cannot deactivate your own Transaction.",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          throw new Error("Failed to activate/deactivate user");
        }
      } else {
        history.push("/List");
        const data = await response.json();
        swal.fire({
          title: data.message,
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error(error);
      swal.fire({
        title: "Failed to activate/deactivate user",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const renewPlasmanByNumber = async (formData) => {
    try {
       const response = await fetchWithAuth(`${baseUrl}renew-plasman/`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        if (response.status === 403) {
          swal.fire({
            title:
              "Permission Denied: \nYou cannot deactivate your own Transaction.",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          throw new Error("Failed to activate/deactivate user");
        }
      } else {
        history.push("/List");
        const data = await response.json();
        swal.fire({
          title: data.message,
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error(error);
      swal.fire({
        title: "Failed to activate/deactivate user",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const InterestWithdrawal = async (formData) => {
    try {
       const response = await fetchWithAuth(`${baseUrl}renew-plasman/`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        if (response.status === 403) {
          swal.fire({
            title:
              "Permission Denied: \nYou cannot deactivate your own Transaction.",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          throw new Error("Failed to activate/deactivate user");
        }
      } else {
        history.push("/List");
        const data = await response.json();
        swal.fire({
          title: data.message,
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error(error);
      swal.fire({
        title: "Failed to activate/deactivate user",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const getTransactionHistoryForAccount = async (accountId) => {
    try {
       const response = await fetchWithAuth(`${baseUrl}transaction-history/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ account_id: accountId }), // Envoyez l'ID du compte
      });
      const data = await response.json();
      console.log('API Response:', data); // Ajoutez ceci pour vérifier la réponse
      if (data) {
        return data
      } else {
        console.error('Error :', data.error);
        return null;
      }
    } catch (error) {
      console.error('Error fetching:', error);
      return null;
    }
  };
  const CalculateInt = async (accountId) => {
    try {
       const response = await fetchWithAuth(`${baseUrl}calculate-daily-interest/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ account: accountId }), // Envoyez l'ID du compte
      });
      const data = await response.json();
      console.log('calculate resp:', data); // Ajoutez ceci pour vérifier la réponse
      if (data) {
        return data
      } else {
        console.error('Error :', data.error);
        return null;
      }
    } catch (error) {
      console.error('Error fetching:', error);
      return null;
    }
  };


  const getHistory = async () => {
    const response = await fetchWithAuth(`${baseUrl}historique-transactions/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

// // console.log(authTokens.access);


//   // Dashoard
  const fetchWithAuth1 = async (url, options = {}) => {
    const response = await fetch(url, {
      ...options,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
        ...options.headers,
      },
    });
    if (!response.ok) {
      swal.fire({
        title: `Error ${response.status}`,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

  const getTotalAccounts = async () => fetchWithAuth1(`${baseUrl}total-accounts/`);
  const getAccountsByType = async () => fetchWithAuth1(`${baseUrl}accounts-by-type/`);
  const getTotalUsers = async () => fetchWithAuth1(`${baseUrl}total-users/`);
  const getCustomerSolTotal = async () => fetchWithAuth1(`${baseUrl}total-costomerSol-count/`);
  const getNbrMainSolTotal = async () => fetchWithAuth1(`${baseUrl}total-costomerSol/`);
  const getAccountsStatus = async () => fetchWithAuth1(`${baseUrl}accounts-status/`);
  const getRecentAccounts = async () => fetchWithAuth1(`${baseUrl}recent-accounts/`);
  const getTotalBalance = async () => fetchWithAuth1(`${baseUrl}total-balance/`);


  const [transactionsData, setTransactionsData] = useState(null);

  const fetchTransactionsData = async (compteId) => {
    try {
      const response = await fetchWithAuth(`${baseUrl}transactions-paiement/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ compte_id: compteId }),
      });
  
      if (!response.ok) {
        throw new Error(`Erreur HTTP ! statut : ${response.status}`);
      }
  
      const data = await response.json();
      console.log("Données récupérées :", data);
      setTransactionsData(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    }
  };
  
  

  const [timeLeft, setTimeLeft] = useState({});

  const fetchTimerData = async (clientId) => {
    try {
      setLoading(true);
      const response = await fetchWithAuth(`${baseUrl}next/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ client_id: clientId }), // Utilisez JSON.stringify ici
      });
  
      if (!response.ok) {
        throw new Error(`Erreur ${response.status}: ${response.statusText}`);
      }
  
      const newData = await response.json();
      console.log('Données de l\'API:', newData); // Vérifiez ici les données
      setTimeLeft((prev) => ({ ...prev, [clientId]: newData }));
    } catch (error) {
      console.error('Erreur lors de l\'appel API', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  // Ajoutez cette fonction à `contextData` dans votre `TransactionProvider`
  const contextData = {
    getTransactions, getTransactionById, updateTransactions, deleteTransactions, getTransactionByCode,
    calculate_interest, renewPlasman, near_end_date, InterestWithdrawal, renewPlasmanByNumber,
    getTransactionHistoryForAccount, getTotalAccounts, getAccountsByType, getTotalUsers, getAccountsStatus,
    getRecentAccounts, getTotalBalance, getCustomerSolTotal, getNbrMainSolTotal,VerifierTransaction,
    createTransactionPL,createTransactionBS,createTransactionMu,createTransactionSol,
    timeLeft, loading1,fetchTimerData ,CalculateInt,getHistory,getPaiement,createPay,
    transactionsData, fetchTransactionsData
  };

  return (
    <TransactionsContext.Provider value={contextData}>
      {children}
    </TransactionsContext.Provider>
  );
};

export { TransactionsContext, TransactionProvider };
