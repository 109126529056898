import React from 'react';
import ReportDashboard from './ReportDashboard';
import { useHistory } from 'react-router-dom';

const ReportPage = () => {
    const history = useHistory();
    
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Centre de Rapports</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="#" onClick={() => history.push('/dashboard')}>
                                        Accueil
                                    </a>
                                </li>
                                <li className="breadcrumb-item active">Rapports</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <ReportDashboard />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportPage;
