import { useContext } from 'react';
import AuthContext from '../components/context/AuthContext';
import { baseUrlTransactions } from '../components/context/BaseUrl';

// Hook personnalisé pour les fonctionnalités de rapport
const useReportService = () => {
  const { fetchWithAuth } = useContext(AuthContext);
  const API_URL = baseUrlTransactions;

  // Fonction pour générer et télécharger le rapport Excel des comptes SOL
  const generateSolAccountsReport = async (month, year) => {
    try {
      // Préparation des paramètres de requête
      let url = new URL(`${API_URL}sol-accounts-report/`);
      
      // Ajout des paramètres à l'URL si fournis
      if (month) url.searchParams.append('month', month);
      if (year) url.searchParams.append('year', year);
      
      // Effectuer la requête avec fetchWithAuth
      const response = await fetchWithAuth(url.toString(), {
        responseType: 'blob', // Important pour recevoir le fichier
      });
      
      // Vérifier si la réponse est OK
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        return { 
          success: false, 
          message: errorData.detail || errorData.error || 'Erreur lors de la génération du rapport' 
        };
      }
      
      // Obtenir le blob de la réponse
      const blob = await response.blob();
      
      // Créer un URL pour le fichier reçu
      const url_blob = window.URL.createObjectURL(blob);
      
      // Créer un lien temporaire pour le téléchargement
      const link = document.createElement('a');
      link.href = url_blob;
      
      // Déterminer le nom du fichier
      const contentDisposition = response.headers.get('content-disposition');
      let filename = 'rapport_comptes_sol.xlsx';
      
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch && filenameMatch.length === 2) {
          filename = filenameMatch[1];
        }
      }
      
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      
      // Déclencher le téléchargement
      link.click();
      
      // Nettoyer
      window.URL.revokeObjectURL(url_blob);
      document.body.removeChild(link);
      
      return { success: true, message: 'Rapport téléchargé avec succès' };
    } catch (error) {
      console.error('Erreur lors de la génération du rapport:', error);
      return { 
        success: false, 
        message: error.message || 'Erreur lors de la génération du rapport' 
      };
    }
  };

  return {
    generateSolAccountsReport
  };
};

export default useReportService;
