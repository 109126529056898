/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from "react-data-table-component";
import { ExportDropdown, customStyles } from "./Utility";
import { Dropdown } from "react-bootstrap";
import { TransactionsContext } from "../context/TransactionsContext";
import { TransactionSolContext } from "../context/TransactionSolContext";
import LoaderData from "../Include/loaderData/loaderData";
import { AccountContext } from "../context/AccountsContext";
import { baseUrlTransactions } from "../context/BaseUrl";
const swal = require("sweetalert2");
const baseUrl = baseUrlTransactions;

const DataTransactions = () => {
  const { getTransactions } = useContext(TransactionsContext); // Récupérez la fonction getAccounts du contexte d'authentification
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const [selectedTransactions, setSelectedTransactions] = useState("Tous les transaction"); // État pour suivre la classe sélectionnée

  const [loading, setLoading] = useState(true);

  const [totalRows, setTotalRows] = useState(0); // Total des lignes
  const [nextUrl, setNextUrl] = useState(null); // URL pour la page suivante
  const [prevUrl, setPrevUrl] = useState(null); // URL pour la page précédente
  const [rowsPerPage, setRowsPerPage] = useState(10); // Lignes par page

  // Fetch paginated data
  const fetchTransactions = async (url) => {
    setLoading(true);
    try {
      const response = await getTransactions(url);
      setRecords(response.results);
      setOriginalRecords(response.results);
      setTotalRows(response.count);
      setNextUrl(response.next); // Stockez l'URL de la page suivante
      setPrevUrl(response.previous); // Stockez l'URL de la page précédente
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors du chargement des transactions:", error);
      setLoading(false);
    }
  };

 // Charge les données initiales
 useEffect(() => {
  fetchTransactions(`${baseUrl}?page=1&page_size=${rowsPerPage}`);
}, [rowsPerPage]);

  if (loading) {
    return <LoaderData />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsTransactions = [
    {
      name: "Compte",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Montant",
      selector: (row) => row.montant,
      sortable: true,
    },
    {
      name: "Ajouter par",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Date Sol",
      selector: (row) => row.date_sol,
      sortable: true,
    },
    {
      name: "Date Transac.",
      selector: (row) => row.date_transaction	,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group " role="group">
          <Link
            to={"/views-Transactions/" + row.id}
            className="btn btn-primary btn-sm me-1"
          >
            <i className="fas fa-eye"></i>
          </Link>
        </div>
      ),
      maxWidth: "50px",
    },
  ];

  const handleFilter = (event) => {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const num_compteLower = row.code.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        num_compteLower.includes(searchLower)
      );
    });

    setRecords(newData);
  };

  const handleFilterByTransactions = (type) => {
    setSelectedTransactions(type);
    if (type === "Tous les transaction") {
      setRecords(originalRecords);
    } else {
      const newData = originalRecords.filter(
        (row) =>
          row.type.toLowerCase() === type.toLowerCase()
      );
      setRecords(newData);
    }
  };

  // Gestion des changements de page
  const handlePageChange = (page) => {
    if (page === 1 && prevUrl) {
      fetchTransactions(prevUrl);
    } else if (page > 1 && nextUrl) {
      fetchTransactions(nextUrl);
    }
  };

  // Gestion du changement du nombre de lignes par page
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    fetchTransactions(`${baseUrl}?page=1&page_size=${newRowsPerPage}`);
  };

  return (
    <div className="container-fluid">
      <div class="card p-4" >
        <div className="container-lg wow fadeIn " data-wow-delay="0.1s ">
          <div className="row">
            <div className="col-md-8 text-start d-flex align-items-center">
              <ExportDropdown
                id="dropdown-item-button"
                title={`Trier par ${selectedTransactions}`}
                menualign="right"
                variant="outline-primary btn btn-primary text-white btn-sm mb-0">
                {/* <Dropdown.ItemText>Selectionner un type</Dropdown.ItemText> */}
                <Dropdown.Item as="button"
                  onClick={() => handleFilterByTransactions("Tous les transaction")}
                >
                  Tous les transaction
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => handleFilterByTransactions("depo_sol")}
                >
                  Dépôt SOL
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => handleFilterByTransactions("depo_bs")}
                >
                  Dépôt BS
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => handleFilterByTransactions("retrait_bs")}
                >
                  Retrait BS
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => handleFilterByTransactions("placement")}
                >
                  Placement
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => handleFilterByTransactions("depo_mutuelle")}
                >
                  Dépôt Mutuelle
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => handleFilterByTransactions("retrait_mutuelle")}
                >
                  Retrait Mutuelle
                </Dropdown.Item>
              </ExportDropdown>
            </div>

            <div className="col-md-4 ">
              <input
                className="form-control form-control-sm mb-2 float-end"
                type="text"
                placeholder="Search"
                onChange={handleFilter}
              />
            </div>
            <div className="col-md-12">
            <DataTable
        columns={columnsTransactions}
        data={records}
        fixedHeader
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        customStyles={customStyles}
        // paginationTotalRows={totalRecords} // Total count of rows
        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]} // Rows per page options
        paginationComponentOptions={{
          rowsPerPageText: "Lignes par page:",
          rangeSeparatorText: "sur",
          noRowsPerPage: false,
          selectAllRowsItem: false,
          selectAllRowsItemText: "Tous",
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        subHeader
        noDataComponent="Aucune donnée trouvée"
      />
    </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const DataDashboard = () => {
  const { getRecentAccounts } = useContext(TransactionsContext); // Récupérez la fonction getAccounts du contexte d'authentification
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const [selectedTransactions, setSelectedTransactions] = useState("Tous"); // État pour suivre la classe sélectionnée

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getRecentAccounts();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        console.log(data);
        setLoading(false);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getRecentAccounts]);

  if (loading) {
    return <LoaderData />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsTransactions = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      maxWidth: "50px",
    },
    {
      name: "Compte",
      selector: (row) => row.code,
      sortable: true,
      maxWidth: "50px",
    },
    {
      name: "Nom Complet",
      selector: (row) => (
        <div className="d-flex align-items-center">
          {row.client_nom + " " + row.client_prenom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.created_at,
      sortable: true,
    },
  ];

  const handleFilter = (event) => {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const nomLower = row.nom.toLowerCase();
      const num_compteLower = row.num_compte.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        nomLower.includes(searchLower) || num_compteLower.includes(searchLower)
      );
    });

    setRecords(newData);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 ml-2 p-2 ">
          <input
            className="form-control"
            type="text"
            placeholder="Search"
            onChange={handleFilter}
          />
        </div>

        <DataTable
          columns={columnsTransactions}
          data={records}
          fixedHeader
          pagination
          customStyles={customStyles}
          paginationPerPage="5"
          paginationRowsPerPageOptions={[5, 10, 20]}
          paginationComponentOptions={{
            rowsPerPageText: "Lignes par page:",
            rangeSeparatorText: "sur",
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: "Tous",
          }}
          subHeader
          noDataComponent="Aucune donnée trouvée"
        />
      </div>
    </>
  );
};
const TransactionHistory = ({ AccountId }) => {
  const { getTransactionHistoryForAccount } = useContext(TransactionsContext);
  const [originalRecords, setOriginalRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getTransactionHistoryForAccount(AccountId); // Passez AccountId à la fonction
        setOriginalRecords(data);
        setRecords(data);
        setLoading(false);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getTransactionHistoryForAccount, AccountId]); // Assurez-vous de dépendre de AccountId pour recharger les données en cas de changement

  if (loading) {
    return <LoaderData />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsTransactions = [
    {
      name: "Type Transaction",
      selector: (row) => (
        <div className="d-flex align-items-center">
          {row.type_transaction === "PL" ? "Plasman" : row.type_transaction}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Montant",
      selector: (row) => row.montant,
      sortable: true,
    },
    // {
    //   name: "Effectuer par",
    //   selector: (row) => row.username,
    //   sortable: true,
    // },
    {
      name: "Date",
      selector: (row) => row.created_at,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group " role="group">
          <Link
            // to={"/views-Transactions/" + row.id}
            className="btn btn-primary btn-sm me-1"
          >
            <i className="fas fa-eye"></i>
          </Link>
        </div>
      ),
      maxWidth: "50px",
    },
  ];

  const handleFilter = (event) => {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const nomLower = row.type_transaction.toLowerCase();
      const num_compteLower = row.montant.toLowerCase();
      const created_atLower = row.created_at.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        nomLower.includes(searchLower) ||
        num_compteLower.includes(searchLower) ||
        created_atLower.includes(searchLower)
      );
    });

    setRecords(newData);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-5 text-start d-flex align-items-center"></div>

        <div className="col-md-7">
          <input
            className="form-control"
            type="text"
            placeholder="Rechercher par Type, Date ou montant"
            onChange={handleFilter}
          />
        </div>

        <div className="col-12">
          <DataTable
            columns={columnsTransactions}
            data={records}
            fixedHeader
            pagination
            customStyles={customStyles}
            paginationPerPage="10"
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page:",
              rangeSeparatorText: "sur",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "Tous",
            }}
            subHeader
            noDataComponent="Aucune donnée trouvée "
          />
        </div>
      </div>
    </>
  );
};
const DataNearEndDate = () => {
  const { near_end_date } = useContext(TransactionsContext);
  const [originalRecords, setOriginalRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await near_end_date(); // Passez AccountId à la fonction
        setOriginalRecords(data);
        setRecords(data);
        setLoading(false);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [near_end_date]); // Assurez-vous de dépendre de AccountId pour recharger les données en cas de changement

  if (loading) {
    return <LoaderData />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsTransactions = [
    {
      name: "Type Transaction",
      selector: (row) => row.type_transaction,
      sortable: true,
    },
    {
      name: "Montant",
      selector: (row) => row.montant,
      sortable: true,
    },
    {
      name: "Effectuer par",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.created_at,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group " role="group">
          <Link
            to={"/views-Transactions/" + row.id}
            className="btn btn-primary btn-sm me-1"
          >
            <i className="fas fa-eye"></i>
          </Link>
        </div>
      ),
      maxWidth: "50px",
    },
  ];

  const handleFilter = (event) => {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const nomLower = row.type_transaction.toLowerCase();
      const num_compteLower = row.montant.toLowerCase();
      const created_atLower = row.created_at.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        nomLower.includes(searchLower) ||
        num_compteLower.includes(searchLower) ||
        created_atLower.includes(searchLower)
      );
    });

    setRecords(newData);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6" />
        <div className="col-md-6">
          <input
            className="form-control form-control-sm"
            type="text"
            placeholder="Rechercher par Numero"
            onChange={handleFilter}
          />
        </div>

        <div className="col-12">
          <DataTable
            columns={columnsTransactions}
            data={records}
            fixedHeader
            pagination
            customStyles={customStyles}
            paginationPerPage="10"
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page:",
              rangeSeparatorText: "sur",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "Tous",
            }}
            subHeader
            noDataComponent="Aucune donnée trouvée dans la table "
          />
        </div>
      </div>
    </>
  );
};
// const DataTransactionsSol = () => {
//   const { getTransactions, deleteTransactions } = useContext(
//     TransactionSolContext
//   ); // Récupérez la fonction getTransactions du contexte d'authentification
//   const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
//   const [records, setRecords] = useState();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const data = await getTransactions();
//         setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
//         setRecords(data);
//         console.log(data);
//       } catch (error) {
//         console.error(error);
//       }
//     };
//     fetchData();
//   }, [getTransactions]);

//   const columnsUser = [
//     {
//       name: "Date",
//       selector: (row) => row.date,
//       sortable: true,
//     },
//     {
//       name: "Client",
//       selector: (row) => (
//         <div className="d-flex align-items-center">
//           {/* <img
//               src={row.image}
//               alt="Photo"
//               style={{ width: 30, height: 30, borderRadius: "50%", marginRight: 5 }}
//             /> */}
//           {row.client_nom}
//         </div>
//       ),
//       sortable: true,
//     },
//     {
//       name: "Mtnt Payé",
//       selector: (row) => row.montant + " Gdes",
//       maxwidth: "50px",
//       sortable: true,
//       paddingRight: "0px",
//     },
//     {
//       name: "Effectué par",
//       selector: (row) => row.created_by,
//       maxwidth: "50px",
//       sortable: true,
//       paddingRight: "0px",
//     },
//     {
//       name: "Actions",
//       cell: (row) => (
//         <div className="btn-group" role="group">
//           <button
//             onClick={() => handleDelete(row.id)}
//             className="btn btn-danger btn-sm "
//           >
//             <i className="fas fa-cancel"></i>
//           </button>
//         </div>
//       ),
//     },
//   ];
//   function handleDelete(id) {
//     swal
//       .fire({
//         title: "Êtes-vous sûr(e) de vouloir Annuler cette transaction?",
//         icon: "warning",
//         showCancelButton: true,
//         confirmButtonColor: "#3085d6",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Oui, Annuler!",
//         cancelButtonText: "Non",
//       })
//       .then(async (result) => {
//         if (result.isConfirmed) {
//           await deleteTransactions(id); // Assuming userId is defined
//           swal.fire("Annulé´!!!");
//           await refreshData();
//         }
//       });
//   }
//   const refreshData = async () => {
//     try {
//       const data = await getTransactions();
//       setOriginalRecords(data);
//       setRecords(data);
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   function handleFilter(event) {
//     const { value } = event.target;
//     if (value === "") {
//       setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
//       return;
//     }

//     const newData = originalRecords.filter((row) => {
//       const nomLower = row.client_nom.toLowerCase();
//       const prenomLower = row.date.toLowerCase();
//       const searchLower = value.toLowerCase();
//       return (
//         nomLower.includes(searchLower) || prenomLower.includes(searchLower)
//       );
//     });

//     setRecords(newData);
//   }

//   return (
//     <>
//       <div className="row wow fadeIn  " data-wow-delay="0.1s ">
//         <div className="col-md-6 "></div>
//         <div className="col-md-6 mt-1">
//           <input
//             className="form-control mb-2"
//             type="text"
//             placeholder="Search"
//             onChange={handleFilter}
//           />
//         </div>

//         <div className="col-12 ">
//           <DataTable
//             columns={columnsUser}
//             data={records}
//             fixedHeader
//             pagination
//             customStyles={customStyles}
//             paginationPerPage="10"
//             paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
//             paginationComponentOptions={{
//               rowsPerPageText: "Lignes par page:",
//               rangeSeparatorText: "sur",
//               noRowsPerPage: false,
//               selectAllRowsItem: false,
//               selectAllRowsItemText: "Tous",
//             }}
//             noDataComponent="Aucune donnée trouvée"
//           />
//         </div>
//       </div>
//     </>
//   );
// };

const DataTransactionsSol = () => {
  const { getTransactions, deleteTransactions } = useContext(
    TransactionSolContext
  );
  const [originalRecords, setOriginalRecords] = useState([]);
  const [records, setRecords] = useState();
  const token = localStorage.getItem("authTokens");
  const role = JSON.parse(token).user.role;
  const [loading, setLoading] = useState(true);

  // Récupération du rôle de l'utilisateur depuis le localStorage
  const userRole = role;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getTransactions();
        setOriginalRecords(data);
        setRecords(data);
        setLoading(false);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getTransactions]);
  // console.log(userRole);

  if (loading) {
    return <LoaderData />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsUser = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Client",
      selector: (row) => (
        <div className="d-flex align-items-center">{row.client_nom}</div>
      ),
      sortable: true,
    },
    {
      name: "Mtnt Payé",
      selector: (row) => row.montant + " Gdes",
      sortable: true,
    },
    {
      name: "Effectué par",
      selector: (row) => row.created_by,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) =>
        userRole === "Admin" && ( // Vérifier si l'utilisateur est Admin avant d'afficher le bouton
          <div className="btn-group" role="group">
            <button
              onClick={() => handleDelete(row.id)}
              className="btn btn-danger btn-sm "
            >
              <i className="fas fa-cancel"></i>
            </button>
          </div>
        ),
    },
  ];

  function handleDelete(id) {
    swal
      .fire({
        title: "Êtes-vous sûr(e) de vouloir Annuler cette transaction?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, Annuler!",
        cancelButtonText: "Non",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteTransactions(id);
          swal.fire("Annulé´!!!");
          await refreshData();
        }
      });
  }

  const refreshData = async () => {
    try {
      const data = await getTransactions();
      setOriginalRecords(data);
      setRecords(data);
    } catch (error) {
      console.error(error);
    }
  };

  function handleFilter(event) {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords);
      return;
    }

    const newData = originalRecords.filter((row) => {
      const nomLower = row.client_nom.toLowerCase();
      const prenomLower = row.date.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        nomLower.includes(searchLower) || prenomLower.includes(searchLower)
      );
    });

    setRecords(newData);
  }

  return (
    <>
      <div className="row wow fadeIn" data-wow-delay="0.1s">
        <div className="col-md-6 "></div>
        <div className="col-md-6 mt-1">
          <input
            className="form-control mb-2"
            type="text"
            placeholder="Search"
            onChange={handleFilter}
          />
        </div>

        <div className="col-12 ">
          <DataTable
            columns={columnsUser}
            data={records}
            fixedHeader
            pagination
            customStyles={customStyles}
            paginationPerPage="10"
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page:",
              rangeSeparatorText: "sur",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "Tous",
            }}
            noDataComponent="Aucune donnée trouvée"
          />
        </div>
      </div>
    </>
  );
};

const DataRapportSol = () => {
  const { getRapportsJours } = useContext(AccountContext); // Récupérez la fonction getTransactions du contexte d'authentification
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getRapportsJours();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        setLoading(false);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getRapportsJours]);

  if (loading) {
    return <LoaderData />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsUser = [
    {
      name: "Compte",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Client",
      selector: (row) => (
        <div className="d-flex align-items-center">
         
          {row.nom}{" "}{row.prenom}
        </div>
      ),
      sortable: true,
      maxwidth: "50px",
    },
    {
      name: "Main",
      selector: (row) => row.nb_main,
      maxwidth: "50px",
      sortable: true,
      paddingRight: "0px",
    },
    {
      name: "Jours Non Payé",
      selector: (row) => row.nb_jours_non_payer,
      maxwidth: "50px",
      sortable: true,
      paddingRight: "0px",
    },
    {
      name: "Montant Non Payé",
      selector: (row) => row.montant_non_payer,
      maxwidth: "50px",
      sortable: true,
      paddingRight: "0px",
    },
    {
      name: "Solde ",
      selector: (row) => row.solde_S,
      maxwidth: "50px",
      sortable: true,
      paddingRight: "0px",
    },
    {
      name: "Dernier Paiement",
      selector: (row) => row.derniere_transaction?.montant_total || 0,
      maxwidth: "50px",
      sortable: true,
      paddingRight: "0px",
    },
  ];

  function handleFilter(event) {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }

    const newData = originalRecords.filter((row) => {
      const nomLower = row.nom.toLowerCase();
      const codeLower = row.code.toLowerCase();
      const prenomLower = row.prenom.toLowerCase();
      const dateLower = row.derniere_transaction.date.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        nomLower.includes(searchLower) || prenomLower.includes(searchLower)
        || dateLower.includes(searchLower) || codeLower.includes(searchLower)
      );
    });

    setRecords(newData);
  }

  return (
    <div className="container-fluid">
    
        <div className="row wow fadeIn  " data-wow-delay="0.1s ">
          <div className="col-md-6 "></div>
          <div className="col-md-6 mt-1">
            <input
              className="form-control form-control-sm mb-2"
              type="text"
              placeholder="Search"
              onChange={handleFilter}
            />
          </div>

          <div className="col-12 ">
            <DataTable
              columns={columnsUser}
              data={records}
              fixedHeader
              pagination
              customStyles={customStyles}
              paginationPerPage="10"
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              paginationComponentOptions={{
                rowsPerPageText: "Lignes par page:",
                rangeSeparatorText: "sur",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "Tous",
              }}
              noDataComponent="Aucune donnée trouvée"
            />
          </div>
      
      </div>
    </div>
  );
};
const DataTransactionsMutuelle = () => {
  const { getTransactionsMutuelle } = useContext(TransactionSolContext); // Récupérez la fonction getTransactions du contexte d'authentification
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getTransactionsMutuelle();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        setLoading(false);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getTransactionsMutuelle]);

  if (loading) {
    return <LoaderData />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsUser = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Client",
      selector: (row) => (
        <div className="d-flex align-items-center">
          {/* <img
              src={row.image}
              alt="Photo"
              style={{ width: 30, height: 30, borderRadius: "50%", marginRight: 5 }}
            /> */}
          {row.client_nom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type_transaction,
      maxwidth: "50px",
      sortable: true,
      paddingRight: "0px",
    },
    {
      name: "Montant",
      selector: (row) => row.montant,
      maxwidth: "50px",
      sortable: true,
      paddingRight: "0px",
    },
    {
      name: "Effectué par",
      selector: (row) => row.created_by,
      maxwidth: "50px",
      sortable: true,
      paddingRight: "0px",
    },
  ];

  function handleFilter(event) {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }

    const newData = originalRecords.filter((row) => {
      const nomLower = row.client_nom.toLowerCase();
      const prenomLower = row.date.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        nomLower.includes(searchLower) || prenomLower.includes(searchLower)
      );
    });

    setRecords(newData);
  }

  return (
    <>
      <div className="row g-2 wow fadeIn  " data-wow-delay="0.1s ">
        <div className="col-md-6 "></div>
        <div className="col-md-6 mt-1">
          <input
            className="form-control mb-2"
            type="text"
            placeholder="Search"
            onChange={handleFilter}
          />
        </div>

        <div className="col-12 ">
          <DataTable
            columns={columnsUser}
            data={records}
            fixedHeader
            pagination
            customStyles={customStyles}
            paginationPerPage="10"
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page:",
              rangeSeparatorText: "sur",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "Tous",
            }}
            noDataComponent="Aucune donnée trouvée"
          />
        </div>
      </div>
    </>
  );
};

const DataMainToucher = () => {
  const { getPaiement } = useContext(TransactionsContext); // Récupérez la fonction getAccounts du contexte d'authentification
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const [selectedTransactions, setSelectedTransactions] = useState("Tous les transaction"); // État pour suivre la classe sélectionnée

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPaiement();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        setLoading(false);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getPaiement]);

  if (loading) {
    return <LoaderData />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsTransactions = [
    {
      name: "Compte",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Nom Complet",
      selector: (row) => (
        <div className="d-flex align-items-center">
          {row.nom + " " + row.prenom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "NB Main",
      selector: (row) => row.main,
      sortable: true,
    },
    {
      name: "Main Toucher",
      selector: (row) => row.nb_main,
      sortable: true,
    },
    {
      name: "Montant",
      selector: (row) => row.montant,
      sortable: true,
    },
    {
      name: "Ajouter par",
      selector: (row) => row.created_by,
      sortable: true,
    },
    {
      name: "Date Transac.",
      selector: (row) => row.date,
      sortable: true,
    },
  ];

  const handleFilter = (event) => {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const num_compteLower = row.code.toLowerCase();
      const nomLower = row.nom.toLowerCase();
      const prenomLower = row.prenom.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        num_compteLower.includes(searchLower) || nomLower.includes(searchLower) ||
        prenomLower.includes(searchLower)
      );
    });

    setRecords(newData);
  };

  return (
    <div className="container-fluid">
      <div class="card p-4" >
        <div className="container-lg wow fadeIn " data-wow-delay="0.1s ">
          <div className="row">
            <div className="col-md-8 text-start d-flex align-items-center">
            
            </div>

            <div className="col-md-4 ">
              <input
                className="form-control form-control-sm mb-2 float-end"
                type="text"
                placeholder="Search"
                onChange={handleFilter}
              />
            </div>

            <div className="col-md-12 ">
              <DataTable
                columns={columnsTransactions}
                data={records}
                fixedHeader
                pagination
                customStyles={customStyles}
                paginationPerPage="10"
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                paginationComponentOptions={{
                  rowsPerPageText: "Lignes par page:",
                  rangeSeparatorText: "sur",
                  noRowsPerPage: false,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "Tous",
                }}
                subHeader
                noDataComponent="Aucune donnée trouvée"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export {
  DataTransactions,
  TransactionHistory,
  DataNearEndDate,
  DataDashboard,
  DataTransactionsSol,
  DataTransactionsMutuelle,
  DataRapportSol,
  DataMainToucher,
};
