/* eslint-disable */
import React, { useEffect } from 'react'
import './styles.css'

function Terms() {
  useEffect(() => {
    document.title = "Bousanm | Terms";
  }, []);

  return (
    <div className="terms-page min-vh-100 bg-light">
      <div className="container text-center d-flex justify-content-between align-items-center">
        <div>
          <a href="mailto:zig.bousanm@gmail.com" className="text-muted small">
            <i className="fas fa-envelope text-primary me-1"></i> zig.bousanm@gmail.com
          </a>
        </div>
        <div>
          <a href="#" className="text-muted small me-1">
            <i className="fa fa-clock text-primary me-0"></i> 7/7 | 24/24
          </a>
        </div>
        <div>
          <a href="https://bousanm.com/" className="btn btn-primary btn-sm py-0">
            <i className="fas fa-home"></i>
          </a>
        </div>
      </div>

      {/* Modern gradient header */}
      <div className="position-relative overflow-hidden bg-gradient"
        style={{
          background: 'linear-gradient(135deg, #0061f2 0%, #00c6f2 100%)',
          padding: '6rem 0 8rem'
        }}>
      </div>

      {/* Content section */}
      <div className="container position-relative z-3" style={{ marginTop: '-13rem' }}>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="card border-0 shadow-lg rounded-3">
              <div className="card-body p-5">
                <div className="row justify-content-center">
                  <div className="col-lg-8 text-center">
                    <h4 className="text-muted">
                      Termes et Conditions d{"'"}Utilisation
                    </h4>
                  </div>
                </div>

                {/* Navigation pills */}
                <div className="d-flex flex-wrap gap-1 mb-5">
                  {['Acceptation', 'Utilisation', 'Protection', 'Responsabilité', 'Modification'].map((item) => (
                    <div key={item} className="terms-pill">
                      <a href={`#${item.toLowerCase()}`} className="btn btn-soft-primary px-4 py-2 rounded-pill">
                        {item}
                      </a>
                    </div>
                  ))}
                </div>

                {/* Acceptation des Conditions */}
                <div id="acceptation" className="terms-section mb-5">
                  <div className="d-flex align-items-center mb-4">
                    <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-primary bg-primary-soft p-2 rounded-3 me-3">
                      <i className="fas fa-check-circle fs-4"></i>
                    </div>
                    <h3 className="mb-0">Acceptation des Conditions</h3>
                  </div>
                  <div className="p-4 bg-light rounded-3">
                    <p className="text-muted mb-0">
                      En installant et en utilisant l{"'"}application Bousanm, l{"'"}utilisateur accepte de se conformer aux présentes conditions d{"'"}utilisation.
                    </p>
                  </div>
                </div>

                {/* Utilisation de l'Application */}
                <div id="utilisation" className="terms-section mb-5">
                  <div className="d-flex align-items-center mb-4">
                    <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-primary bg-primary-soft p-2 rounded-3 me-3">
                      <i className="fas fa-mobile-alt fs-4"></i>
                    </div>
                    <h3 className="mb-0">Utilisation de l{"'"}Application</h3>
                  </div>
                  <div className="row g-4">
                    <div className="col-md-6">
                      <div className="card h-100 border-0 shadow-sm">
                        <div className="card-body">
                          <div className="icon-box d-inline-flex align-items-center justify-content-center bg-primary-soft rounded-circle mb-3" style={{ width: '48px', height: '48px' }}>
                            <i className="fas fa-exchange-alt text-primary"></i>
                          </div>
                          <h5 className="mb-2">Transactions Financières</h5>
                          <p className="text-muted mb-0">L{"'"}application Bousanm est destinée à faciliter les transactions financières et l{"'"}accès aux services de notre plateforme.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card h-100 border-0 shadow-sm">
                        <div className="card-body">
                          <div className="icon-box d-inline-flex align-items-center justify-content-center bg-primary-soft rounded-circle mb-3" style={{ width: '48px', height: '48px' }}>
                            <i className="fas fa-shield-alt text-primary"></i>
                          </div>
                          <h5 className="mb-2">Utilisation Appropriée</h5>
                          <p className="text-muted mb-0">Toute utilisation abusive ou frauduleuse entraînera la suspension du compte.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Protection des Données */}
                <div id="protection" className="terms-section mb-5">
                  <div className="d-flex align-items-center mb-4">
                    <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-primary bg-primary-soft p-2 rounded-3 me-3">
                      <i className="fas fa-lock fs-4"></i>
                    </div>
                    <h3 className="mb-0">Protection des Données</h3>
                  </div>
                  <div className="p-4 bg-light rounded-3">
                    <p className="text-muted mb-0">
                      Bousanm s{"'"}engage à protéger les données personnelles des utilisateurs conformément aux lois en vigueur sur la protection de la vie privée.
                    </p>
                  </div>
                </div>

                {/* Responsabilité de l'Utilisateur */}
                <div id="responsabilité" className="terms-section mb-5">
                  <div className="d-flex align-items-center mb-4">
                    <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-primary bg-primary-soft p-2 rounded-3 me-3">
                      <i className="fas fa-user-shield fs-4"></i>
                    </div>
                    <h3 className="mb-0">Responsabilité de l{"'"}Utilisateur</h3>
                  </div>
                  <div className="row g-4">
                    {[
                      "L'utilisateur est responsable de la confidentialité de ses informations de connexion",
                      "L'utilisateur est responsable des activités réalisées via son compte"
                    ].map((item, index) => (
                      <div key={index} className="col-md-6">
                        <div className="d-flex align-items-center p-3 border rounded-3 bg-light">
                          <div className="icon-box d-inline-flex align-items-center justify-content-center bg-primary rounded-circle me-3" style={{ width: '32px', height: '32px' }}>
                            <i className="fas fa-check text-white"></i>
                          </div>
                          <p className="mb-0">{item}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Modification des Conditions */}
                <div id="modification" className="terms-section mb-5">
                  <div className="d-flex align-items-center mb-4">
                    <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-primary bg-primary-soft p-2 rounded-3 me-3">
                      <i className="fas fa-edit fs-4"></i>
                    </div>
                    <h3 className="mb-0">Modification des Conditions</h3>
                  </div>
                  <div className="p-4 bg-light rounded-3">
                    <p className="text-muted mb-0">
                      Bousanm se réserve le droit de modifier ces conditions à tout moment. Les modifications seront notifiées via l{"'"}application ou par email.
                    </p>
                  </div>
                </div>

                {/* Contact */}
                <div className="text-center">
                  <h3 className="mb-4">Des questions ?</h3>
                  <p className="mb-4">Pour toute question relative aux conditions d{"'"}utilisation, veuillez contacter notre support</p>
                  <a href="mailto:info@ziginvestment.com" className="btn btn-primary btn-small rounded-pill px-5">
                    <i className="fas fa-envelope me-2"></i>
                    Contactez-nous
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms