/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from "react-data-table-component";
import { ExportDropdown, customStyles } from "./Utility";
import { Dropdown } from "react-bootstrap";
import { AccountContext } from "../context/AccountsContext";
import LoaderData from "../Include/loaderData/loaderData";
import AuthContext from "../context/AuthContext";
import 'jspdf-autotable';
import jsPDF from 'jspdf';
const swal = require("sweetalert2");

const exportPDF = async (data) => {
  const doc = new jsPDF('p', 'pt', 'letter');

  doc.setFontSize(14);
  doc.setFont('times', 'bold');
  doc.text(`Liste des Agents`, 25, 60);

  // Sort data by concatenated 'nom' and 'prenom'
  const sortedData = data.sort((a, b) => {
    const fullNameA = `${a.client_nom} ${a.client_prenom}`.toLowerCase();
    const fullNameB = `${b.client_nom} ${b.client_prenom}`.toLowerCase();
    return fullNameA.localeCompare(fullNameB);
  });

  // Notes Table
  const tableColumn = ["code", "Nom", "Prénom", "Type", "username", "password"];
  const tableRows = sortedData.map((note) => [
    note.code,
    note.client_nom,
    note.client_prenom,
    note.type_compte,
    note.username,
    note.pass_send,
  ]);

  doc.autoTable({
    startY: 70,
    head: [tableColumn],
    body: tableRows,
    theme: 'grid',
    styles: {
      font: 'times',
      fontSize: 11,
      cellPadding: 2,
      overflow: 'linebreak'
    },
    headStyles: { halign: 'center', valign: 'middle', fillColor: [1, 80, 133], textColor: [255, 255, 255] },
    bodyStyles: {
      valign: 'middle',
      textColor: [22, 22, 22],
      halign: 'center'
    },
    columnStyles: {
      0: { cellWidth: 80, halign: 'center' },
      1: { cellWidth: 100, halign: 'left', fontStyle: 'bold' },
      2: { cellWidth: 100, halign: 'left', fontStyle: 'bold' },
      3: { cellWidth: 80, halign: 'center' },
    }
  });

  doc.save(`Liste_Agents.pdf`);
}

const DataAgents = () => {
  const [loadingExport, setLoadingExport] = useState(false);
  const { getAgents, activateDeactivateAccount } = useContext(AccountContext);
  const [originalRecords, setOriginalRecords] = useState([]);
  const [records, setRecords] = useState();
  const [selectedType, setSelectedType] = useState("Tous les types");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAgents();
        setOriginalRecords(data);
        setRecords(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getAgents]);

  if (loading) {
    return <LoaderData />;
  }

  const handleExport = async (type) => {
    setLoadingExport(true);
    try {
      const dataToExport =
        selectedType === 'Tous les types'
          ? records
          : originalRecords.filter(
            (row) => row.type_compte === selectedType
          );

      if (dataToExport.length === 0) {
        swal.fire({
          title: 'Table vide',
          text: 'Aucune donnée à exporter',
          icon: 'warning',
          toast: true,
          timer: 3000,
          position: 'top-right',
          timerProgressBar: true,
          showConfirmButton: false,
        });
        return;
      }

      switch (type) {
        case 'pdf':
          await exportPDF(dataToExport);
          break;
        default:
          swal.fire({
            title: 'Type non supporté',
            text: 'Seul le type PDF est supporté pour le moment',
            icon: 'info',
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
          });
      }
    } finally {
      setLoadingExport(false);
    }
  };

  const columnsAgent = [
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Nom Complet",
      selector: (row) => (
        <div>
          <div className="font-weight-bold">{row.nom} {row.prenom}</div>
          <div className="text-muted small">{row.email}</div>
        </div>
      ),
      sortable: true,
      sortFunction: (a, b) => {
        const fullNameA = `${a.nom} ${a.prenom}`.toLowerCase();
        const fullNameB = `${b.nom} ${b.prenom}`.toLowerCase();
        return fullNameA.localeCompare(fullNameB);
      },
    },
    {
      name: "Type",
      selector: (row) => row.type_compte,
      sortable: true,
    },
    {
      name: "Solde",
      selector: (row) => (
        <div>
          <div className="font-weight-bold">{row.devise} {row.solde}</div>
        </div>
      ),
      sortable: true,
      sortFunction: (a, b) => {
        return parseFloat(a.solde) - parseFloat(b.solde);
      },
    },
    {
      name: "Status",
      selector: (row) => (
        <div>
          {row.etat === "Actif" ? (
            <span className="badge badge-success">Actif</span>
          ) : (
            <span className="badge badge-danger">Inactif</span>
          )}
        </div>
      ),
      sortable: true,
      sortFunction: (a, b) => {
        return a.etat === b.etat ? 0 : a.etat === "Actif" ? -1 : 1;
      },
      maxWidth: "50px",
    },
    {
      name: "Username",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Password",
      selector: (row) => row.pass_send,
      sortable: true,
    },
    {
      name: "PIN",
      selector: (row) => row.pin,
      sortable: true,
      maxWidth: "50px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex">
          <Link
            to={`/agent/${row.id}`}
            className="btn btn-primary btn-sm mr-2"
            title="Voir"
          >
            <i className="fas fa-eye"></i>
          </Link>
          <Link
            to={`/edit-agent/${row.id}`}
            className="btn btn-info btn-sm mr-2"
            title="Modifier"
          >
            <i className="fas fa-edit"></i>
          </Link>
          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-basic"
              size="sm"
              className="btn-sm"
            >
              <i className="fas fa-ellipsis-v"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  swal
                    .fire({
                      title: "Êtes-vous sûr?",
                      text: row.etat === "Actif"
                        ? "Voulez-vous désactiver ce compte?"
                        : "Voulez-vous activer ce compte?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: row.etat === "Actif"
                        ? "Oui, désactiver!"
                        : "Oui, activer!",
                      cancelButtonText: "Annuler",
                    })
                    .then((result) => {
                      if (result.isConfirmed) {
                        activateDeactivateAccount(row.id, row.etat === "Actif" ? "Inactif" : "Actif");
                      }
                    });
                }}
              >
                {row.etat === "Actif" ? "Désactiver" : "Activer"}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  swal
                    .fire({
                      title: "Réinitialiser le mot de passe?",
                      text: "Cette action réinitialisera le mot de passe de l'utilisateur.",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Oui, réinitialiser!",
                      cancelButtonText: "Annuler",
                    })
                    .then((result) => {
                      if (result.isConfirmed) {
                        // Appel à la fonction de réinitialisation du mot de passe
                      }
                    });
                }}
              >
                Réinitialiser mot de passe
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    },
  ];

  // Filtrer les données en fonction du type sélectionné
  const handleTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedType(selectedType);

    if (selectedType === "Tous les types") {
      setRecords(originalRecords);
    } else {
      const filteredData = originalRecords.filter(
        (row) => row.type_compte === selectedType
      );
      setRecords(filteredData);
    }
  };

  // Obtenir les types uniques pour le filtre
  const uniqueTypes = ["Tous les types", ...new Set(originalRecords.map(item => item.type_compte))];

  return (
    <div className="row">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <h5 className="mb-0 mr-2">Filtrer par type:</h5>
            <select
              className="form-control ml-2"
              value={selectedType}
              onChange={handleTypeChange}
            >
              {uniqueTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <ExportDropdown onExport={handleExport} loading={loadingExport} />
        </div>
      </div>
      <div className="row">
        <DataTable
          columns={columnsAgent}
          data={records}
          pagination
          highlightOnHover
          customStyles={customStyles}
          noDataComponent={
            <div className="p-4">
              <p className="text-center">Aucun agent trouvé</p>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default DataAgents;
