import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Include/Sidebar";
import { Link } from "react-router-dom";
import { TransactionsContext } from "../../context/TransactionsContext";
import AuthContext from "../../context/AuthContext";
import { AccountContext } from "../../context/AccountsContext";
const swal = require("sweetalert2");

function AddTransaction() {
  const { getAccountDetailByCode, getAllAccountDetail } =
    useContext(AccountContext);
  const {
    VerifierTransaction,
    createTransactionMu,
    createTransactionPL,
    createTransactionSol,
    createTransactionBS,
  } = useContext(TransactionsContext);
  // for select type transaction
  const [transactionType, setTransactionType] = useState("");
  const [showAdditionalFields, setShowAdditionalFields] = useState({
    mutuelle: false,
    pl: false,
  });
  const [dataAccount, setDataAccount] = useState(null); // Exemple de données du compte
  const [isTransactionValid, setIsTransactionValid] = useState(true); // État pour suivre si la transaction est valide

  // Fonction pour gérer la sélection du type de transaction
  const handleTypeChange = async (e) => {
    const selectedType = e.target.value;
    setTransactionType(selectedType);
    setIsTransactionValid(true); // Réinitialiser l'état de validation
    setErrors({}); // Réinitialiser les erreurs

    // Vérifier si le compte existe et valider le type de transaction en fonction du type de compte
    if (dataAccount) {
      // Validation pour les comptes SOL
      if (dataAccount.type_compte === "SOL" && selectedType !== "depo_sol") {
        setErrors({
          type: "Type de transaction invalide pour un compte SOL. Seul le dépôt SOL est autorisé.",
        });
        setIsTransactionValid(false);
        return;
      }

      // Validation pour les comptes BS
      if (dataAccount.type_compte === "BS" && selectedType === "depo_sol") {
        setErrors({
          type: "Type de transaction invalide pour un compte BS. Le dépôt SOL n'est pas autorisé.",
        });
        setIsTransactionValid(false);
        return;
      }

      const formData = new FormData();
      formData.append("code", dataAccount.code);
      formData.append("type", selectedType);

      const result = await VerifierTransaction(formData);

      // Si transaction_exists est 0, afficher les champs supplémentaires
      if (result && result.transaction_exists === 0) {
        if (selectedType === "depo_mutuelle") {
          setShowAdditionalFields((prevState) => ({
            ...prevState,
            mutuelle: true,
          }));
        } else if (selectedType === "placement") {
          setShowAdditionalFields((prevState) => ({ ...prevState, pl: true }));
        }
      } else {
        setShowAdditionalFields({ mutuelle: false, pl: false }); // Réinitialiser si la transaction existe déjà
      }
    }
  };

  const [code, setcode] = useState({ code: "" });
  // const [dataAccount, setDataAccount] = useState();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleFilterAccounts = () => {
    const value = code.code;
    if (value === "") {
      swal.fire({
        title: "Entrer Numero du compte",
        icon: "warning",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    let formData = new FormData();
    formData.append("code", value);

    getAccountDetailByCode(value).then((data) => {
      if (data) {
        setDataAccount(data); // Mettez à jour records avec les données récupérées
      }
    });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const type_transaction = e.target.type.value;
  //   const montant = e.target.montant.value;

  //   if (!dataAccount) {
  //     swal.fire({
  //       title: "Veuillez rechercher le compte",
  //       icon: "warning",
  //       toast: true,
  //       timer: 3000,
  //       position: "top-right",
  //       timerProgressBar: true,
  //       showConfirmButton: false,
  //     });
  //     return;
  //   }

  //   const compte = dataAccount.id;
  //   if (!montant) {
  //     swal.fire({
  //       title: "Veuillez entrer le montant",
  //       icon: "warning",
  //       toast: true,
  //       timer: 3000,
  //       position: "top-right",
  //       timerProgressBar: true,
  //       showConfirmButton: false,
  //     });
  //     return;
  //   }
  //   if (!transactionType) {
  //     swal.fire({
  //       title: "Veuillez sélectionner un type de transaction.",
  //       icon: "error",
  //       toast: true,
  //       timer: 3000,
  //       position: "top-right",
  //       timerProgressBar: true,
  //       showConfirmButton: false,
  //     });
  //     return false;
  //   }
  //   let formData = new FormData();
  //   formData.append("compte", compte);
  //   formData.append("type", type_transaction);
  //   formData.append("montant", montant);
  //   formData.append("created_by", user.user_id);

  //   try {
  //     setLoading(true); // Activer le loader
  //     if (transactionType === "depo_mutuelle" || transactionType === "retrait_mutuelle") {
  //       if(showAdditionalFields.mutuelle){
  //         const dureeM = e.target.dureeM.value;
  //         const date_debutM = e.target.date_debutM.value;

  //         formData.append("dureeM", dureeM || "");
  //         formData.append("date_debutM", date_debutM || "");
  //       }
  //     await createTransactionMu(formData);
  //     }
  //     else if (transactionType === "placement") {
  //       if(showAdditionalFields.pl){
  //         const date_debutP = e.target.date_debutP.value;
  //         const dureeP = e.target.dureeP.value;

  //         formData.append("dureeP", dureeP ||"");
  //         formData.append("date_debutP", date_debutP || "");
  //       }
  //     await createTransactionPL(formData);
  //     }
  //     else if (transactionType === "depo_bs" || transactionType === "retrait_bs") {
  //     await createTransactionBS(formData);
  //     }
  //     else if (transactionType === "depo_sol") {
  //     await createTransactionSol(formData);
  //     }
  //   } catch (error) {
  //     swal.fire({
  //       title: "Erreur lors de Transaction",
  //       text: error.message || "Une erreur s'est produite",
  //       icon: "error",
  //       toast: true,
  //       timer: 3000,
  //       position: "top-right",
  //       timerProgressBar: true,
  //       showConfirmButton: false,
  //     });
  //   } finally {
  //     setLoading(false); // Désactiver le loader
  //   }
  // };
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Récupérer les valeurs des champs
    const type_transaction = e.target.type.value;
    const montant = e.target.montant.value;

    // Vider immédiatement les champs après récupération
    e.target.type.value = ""; // Vider le champ type
    e.target.montant.value = ""; // Vider le champ montant

    let formErrors = {};

    // Vérifications de base
    if (!dataAccount) {
      formErrors.compte = "Veuillez rechercher un compte.";
    }

    if (!montant) {
      formErrors.montant = "Veuillez entrer le montant.";
    }

    if (!type_transaction) {
      formErrors.type = "Veuillez sélectionner un type de transaction.";
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    let formData = new FormData();
    formData.append("compte", dataAccount.id);
    formData.append("type", type_transaction);
    formData.append("montant", montant);
    formData.append("created_by", user.user_id);

    try {
      setLoading(true); // Activer le loader
      setErrors({}); // Réinitialiser les erreurs avant la soumission

      // En fonction du type de transaction, ajouter des données supplémentaires
      if (
        transactionType === "depo_mutuelle" ||
        transactionType === "retrait_mutuelle"
      ) {
        const dureeM = e.target.dureeM?.value || "";
        const date_debutM = e.target.date_debutM?.value || "";
        formData.append("dureeM", dureeM);
        formData.append("date_debutM", date_debutM);

        await createTransactionMu(formData);
      } else if (transactionType === "placement") {
        const dureeP = e.target.dureeP?.value || "";
        const date_debutP = e.target.date_debutP?.value || "";
        formData.append("dureeP", dureeP);
        formData.append("date_debutP", date_debutP);

        await createTransactionPL(formData);
      } else if (
        transactionType === "depo_bs" ||
        transactionType === "retrait_bs"
      ) {
        await createTransactionBS(formData);
      } else if (transactionType === "depo_sol") {
        await createTransactionSol(formData);
      }

      // Réinitialiser le formulaire après succès
      e.target.reset();
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      } else {
        setErrors({ general: "Une erreur inattendue s'est produite." });
      }
    } finally {
      setLoading(false); // Désactiver le loader
    }
  };

  const [allAccount, setAllAccount] = useState([]);
  const [filteredAccount, setFilteredAccount] = useState([]);
  const [searchText, setSearchText] = useState("");
  // const [dataAccount, setDataAccount] = useState(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const data = await getAllAccountDetail(); // Appel direct à la méthode du contexte
  //       setAllAccount(data); // Mise à jour de l'état avec les données
  //       setFilteredAccount(data); // Initialement, tous les comptes sont affichés
  //     } catch (error) {
  //       console.error("Erreur lors de la récupération des comptes :", error);
  //     }
  //   };
  //   fetchData();
  // }, [getAllAccountDetail]);

  // const handleSearchChange = (text) => {
  //   setSearchText(text);
  //   const filtered = allAccount.filter((account) =>
  //     account.code.toLowerCase().includes(text.toLowerCase())
  //   );
  //   setFilteredAccount(filtered);
  // };

  // const handleSelectStudent = (e) => {
  //   const selectedCode1 = e.target.value;
  //   const selectedStudent = allAccount.find(
  //     (student) => student.code === selectedCode1
  //   );
  //   if (selectedStudent) {
  //     setDataAccount(selectedStudent);
  //   }
  // };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllAccountDetail(); // Appel direct à la méthode du contexte
        setAllAccount(data); // Mise à jour de l'état avec les données
        setFilteredAccount(data); // Initialement, tous les comptes sont affichés
      } catch (error) {
        console.error("Erreur lors de la récupération des comptes :", error);
      }
    };
    fetchData();
  }, [getAllAccountDetail]);

  const handleSearchChange = (text) => {
    setSearchText(text);

    // Filtrage par `code`, `nom` ou `prenom`
    const filtered = allAccount.filter((account) =>
      [account.code, account.client_nom, account.client_prenom].some((field) =>
        field?.toLowerCase().includes(text.toLowerCase())
      )
    );

    setFilteredAccount(filtered);
  };

  const handleSelectStudent = (e) => {
    const selectedCode1 = e.target.value;
    const selectedStudent = allAccount.find(
      (student) => student.code === selectedCode1
    );
    if (selectedStudent) {
      setDataAccount(selectedStudent);
    }
  };

  return (
    <div className="layout-fixed sidebar-expand-lg bg-body-tertiary">
      <div className="app-wrapper">
        <Sidebar />

        <main className="app-main">
          <div className="app-content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="mb-0">Gestion des Transactions</h3>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right py-0 px-4">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Tablau de bord</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/transaction">Transactions</Link>
                    </li>
                    <li className="breadcrumb-item">Nouvelle Transaction</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="app-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-6 col-lg-5">
                  <div className="card">
                    <div className="card-body">
                      <div className="row g-2 mx-auto">
                        <div className="col-md-12">
                          <h3 className="h3">Recherche Compte</h3>
                        </div>
                        <div className="col-md-12">
                          <div className="input-group mb-1">
                            {/* Champ de recherche */}
                            <input
                              className={`form-control form-control-sm fw-9 ${
                                errors.compte ? "is-invalid" : ""
                              }`}
                              type="text"
                              id="search"
                              placeholder="Rechercher par Code"
                              value={searchText ? searchText : ""}
                              onChange={(e) =>
                                handleSearchChange(e.target.value)
                              }
                            />
                            {errors.compte && (
                              <div className="invalid-feedback">
                                {errors.compte}
                              </div>
                            )}

                            {/* Liste des comptes filtrés */}
                            <select
                              className={`form-control form-control-sm  p-0 ${
                                errors.compte ? "is-invalid" : ""
                              }`}
                              size="1" // Affiche plusieurs options en même temps
                              onChange={handleSelectStudent}
                            >
                              <option value={""} selected></option>
                              {filteredAccount.map((student) => (
                                <option key={student.code} value={student.code}>
                                  {student.code} - {student.client_nom}{" "}
                                  {student.client_prenom}
                                </option>
                              ))}
                            </select>
                            {errors.compte && (
                              <div className="invalid-feedback">
                                {errors.compte}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* Affichage des détails du compte sélectionné */}
                        {dataAccount && (
                          <div className="col-md-12 mb-3">
                            <div className="card border-0 shadow-sm">
                              <div className="card-header bg-primary text-white">
                                <h5 className="mb-0">Informations du compte</h5>
                              </div>
                              <div className="card-body">
                                {/* Informations communes à tous les types de comptes */}
                                <div className="row mb-3">
                                  <div className="col-md-6">
                                    <label className="form-label fw-bold small">
                                      Code
                                    </label>
                                    <input
                                      type="text"
                                      disabled
                                      value={dataAccount.code || ""}
                                      className="form-control form-control-sm bg-light"
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <label className="form-label fw-bold small">
                                      Nom complet
                                    </label>
                                    <input
                                      type="text"
                                      disabled
                                      value={`${dataAccount.nom || ""} ${
                                        dataAccount.prenom || ""
                                      }`}
                                      className="form-control form-control-sm bg-light"
                                    />
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-md-6">
                                    <label className="form-label fw-bold small">
                                      Type de compte
                                    </label>
                                    <input
                                      type="text"
                                      disabled
                                      value={dataAccount.type_compte || ""}
                                      className="form-control form-control-sm bg-light"
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <label className="form-label fw-bold small">
                                      État
                                    </label>
                                    <input
                                      type="text"
                                      disabled
                                      value={dataAccount.etat || ""}
                                      className="form-control form-control-sm bg-light"
                                    />
                                  </div>
                                </div>

                                {/* Informations spécifiques pour les comptes SOL */}
                                {dataAccount.type_compte === "SOL" && (
                                  <>
                                    <div className="row mb-3">
                                      <div className="col-md-4">
                                        <label className="form-label fw-bold small">
                                          Nombre de mains
                                        </label>
                                        <input
                                          type="text"
                                          disabled
                                          value={dataAccount.nb_main || ""}
                                          className="form-control form-control-sm bg-light"
                                        />
                                      </div>
                                      <div className="col-md-4">
                                        <label className="form-label fw-bold small">
                                          Montant journalier
                                        </label>
                                        <input
                                          type="text"
                                          disabled
                                          value={
                                            dataAccount.montant_jours || ""
                                          }
                                          className="form-control form-control-sm bg-light"
                                        />
                                      </div>
                                      <div className="col-md-4">
                                        <label className="form-label fw-bold small">
                                          Solde SOL
                                        </label>
                                        <input
                                          type="text"
                                          disabled
                                          value={dataAccount.solde_S || ""}
                                          className="form-control form-control-sm bg-light"
                                        />
                                      </div>
                                    </div>

                                    {/* Barre de progression pour les paiements */}
                                    <div className="mb-3">
                                      <label className="form-label fw-bold small">
                                        Progression des paiements
                                      </label>
                                      <div
                                        className="progress"
                                        style={{ height: "20px" }}
                                      >
                                        <div
                                          className="progress-bar bg-success"
                                          role="progressbar"
                                          style={{
                                            width: `${
                                              (dataAccount.nb_jours_payer /
                                                dataAccount.total_jours) *
                                              100
                                            }%`,
                                          }}
                                          aria-valuenow={
                                            dataAccount.nb_jours_payer
                                          }
                                          aria-valuemin="0"
                                          aria-valuemax={
                                            dataAccount.total_jours
                                          }
                                        >
                                          {dataAccount.nb_jours_payer} jours
                                          payés
                                        </div>
                                        <div
                                          className="progress-bar bg-danger"
                                          role="progressbar"
                                          style={{
                                            width: `${
                                              (dataAccount.nb_jours_non_payer /
                                                dataAccount.total_jours) *
                                              100
                                            }%`,
                                          }}
                                          aria-valuenow={
                                            dataAccount.nb_jours_non_payer
                                          }
                                          aria-valuemin="0"
                                          aria-valuemax={
                                            dataAccount.total_jours
                                          }
                                        >
                                          {dataAccount.nb_jours_non_payer} jours
                                          non payés
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between mt-1">
                                        <small>
                                          Total: {dataAccount.total_jours} jours
                                        </small>
                                        <small className="text-danger">
                                          Montant non payé:{" "}
                                          {dataAccount.montant_non_payer}{" "}
                                          {dataAccount.devise}
                                        </small>
                                      </div>
                                    </div>

                                    {/* Dernière transaction */}
                                    {dataAccount.derniere_transaction && (
                                      <div className="card mb-0">
                                        <div className="card-body p-3">
                                          <h6 className="card-title mb-2">
                                            Dernière transaction
                                          </h6>
                                          <p className="mb-0">
                                            <strong>Date:</strong>{" "}
                                            {
                                              dataAccount.derniere_transaction
                                                .date
                                            }{" "}
                                            |<strong>Type:</strong>{" "}
                                            {
                                              dataAccount.derniere_transaction
                                                .type
                                            }{" "}
                                            |<strong>Montant:</strong>{" "}
                                            {
                                              dataAccount.derniere_transaction
                                                .montant_total
                                            }{" "}
                                            {dataAccount.devise}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}

                                {/* Informations spécifiques pour les comptes BS */}
                                {dataAccount.type_compte === "BS" && (
                                  <div className="row">
                                    <div className="col-md-4">
                                      <label className="form-label fw-bold small">
                                        Solde BS
                                      </label>
                                      <input
                                        type="text"
                                        disabled
                                        value={dataAccount.solde_BS || ""}
                                        className="form-control form-control-sm bg-light"
                                      />
                                    </div>

                                    {/* Afficher solde PL si > 0 */}
                                    {parseFloat(dataAccount.solde_PL) > 0 && (
                                      <div className="col-md-4">
                                        <label className="form-label fw-bold small">
                                          Solde Plasman
                                        </label>
                                        <input
                                          type="text"
                                          disabled
                                          value={dataAccount.solde_PL || ""}
                                          className="form-control form-control-sm bg-light"
                                        />
                                      </div>
                                    )}

                                    {/* Afficher solde M si > 0 */}
                                    {parseFloat(dataAccount.solde_M) > 0 && (
                                      <div className="col-md-4">
                                        <label className="form-label fw-bold small">
                                          Solde Mutuelle
                                        </label>
                                        <input
                                          type="text"
                                          disabled
                                          value={dataAccount.solde_M || ""}
                                          className="form-control form-control-sm bg-light"
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-3" style={{ display: "none" }}>
                          <input
                            type="text"
                            disabled
                            placeholder="Etat du compte"
                            value={dataAccount?.solde_S || ""}
                            className="form-control form-control-sm"
                          />
                          <span className="text-muted fs-6 text-center">
                            Sol
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Transactions */}
                {dataAccount?.etat !== "Inactif" ? (
                  <div className="col-xl-6 col-lg-5">
                    <div className="card shadow mb-4">
                      <div className="card-body">
                        <form
                          className="row g-2 mx-auto"
                          onSubmit={handleSubmit}
                          encType="multipart/form-data"
                        >
                          <div className="col-md-12">
                            <h3 className="h3">Transaction</h3>
                          </div>
                          <div className="col-md-6">
                            Selectioner le type<span className="me-3"></span>{" "}
                            {"     ==>"}
                          </div>
                          <div className="col-md-6">
                            <select
                              className={`form-select form-select-sm ${
                                errors.type ? "is-invalid" : ""
                              }`}
                              name="type"
                              aria-label="type_transaction"
                              id="transactionType"
                              value={transactionType}
                              onChange={handleTypeChange}
                            >
                              <option value="" disabled>
                                Type Transaction
                              </option>
                              <option value="depo_sol">Dépôt SOL</option>
                              <option value="depo_bs">Dépôt BS</option>
                              <option value="retrait_bs">Retrait BS</option>
                              <option value="depo_mutuelle">
                                Dépôt Mutuelle
                              </option>
                              <option value="retrait_mutuelle">
                                Retrait Mutuelle
                              </option>
                              <option value="placement">Placement</option>
                            </select>
                            {errors.type && (
                              <div className="invalid-feedback">
                                {errors.type}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6">
                            <input
                              type="number"
                              className={`form-control form-control-sm ${
                                errors.montant ? "is-invalid" : ""
                              }`}
                              name="montant"
                              placeholder="Montant"
                              id="montant"
                              aria-label="montant"
                              aria-describedby="basic-addon3"
                            />
                            {errors.montant && (
                              <div className="invalid-feedback">
                                {errors.montant}
                              </div>
                            )}
                          </div>
                          {/* for Depot mutuelle input */}
                          {transactionType === "depo_mutuelle" &&
                            showAdditionalFields.mutuelle && (
                              <>
                                <div className="col-md-3">
                                  <input
                                    type="number"
                                    name="dureeM"
                                    placeholder="Duree Mutuelle"
                                    className={`form-control form-control-sm ${
                                      errors.dureeM ? "is-invalid" : ""
                                    }`}
                                  />
                                  {errors.dureeM && (
                                    <div className="invalid-feedback">
                                      {errors.dureeM}
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-3">
                                  <input
                                    type="date"
                                    name="date_debutM"
                                    placeholder="date"
                                    className={`form-control form-control-sm ${
                                      errors.date_debutM ? "is-invalid" : ""
                                    }`}
                                  />
                                  {errors.date_debutM && (
                                    <div className="invalid-feedback">
                                      {errors.date_debutM}
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          {/* end Depot mutuelle input */}

                          {/* for Depot sol input */}
                          {transactionType === "SOL" && <></>}
                          {/* end Depot sol input */}

                          {/* for Placement input */}
                          {transactionType === "placement" &&
                            showAdditionalFields.pl && (
                              <>
                                <div className="col-md-3">
                                  <input
                                    type="number"
                                    name="dureeP"
                                    placeholder="Duree Placement"
                                    className="form-control form-control-sm"
                                  />
                                </div>
                                <div className="col-md-3">
                                  <input
                                    type="date"
                                    name="date_debutP"
                                    placeholder="date"
                                    className="form-control form-control-sm"
                                  />
                                </div>
                              </>
                            )}
                          {/* end Placement input */}
                          <div className="col-md-12" />

                          {errors.general && (
                            <div className="alert alert-danger">
                              {errors.general}
                            </div>
                          )}

                          <div className="col-md-6">
                            <div className="input-group">
                              <button
                                style={{ width: "100%" }}
                                type="submit"
                                className="btn btn-primary btn-sm"
                                disabled={loading || !isTransactionValid}
                              >
                                {loading ? (
                                  <>
                                    <i className="fas fa-spinner fa-spin"></i>{" "}
                                    Validation en cours...
                                  </>
                                ) : (
                                  <>Valider</>
                                )}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-xl-6 col-lg-5">
                    <div className="card shadow mb-4">
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Transaction sur le compte
                        </h6>
                      </div>
                      <div className="card-body">
                        <p className="text-danger">
                          Le compte est inactif. Les transactions sont
                          désactivées.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {/*End Transactions */}
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </main>
      </div>
    </div>
  );
}

export default AddTransaction;
