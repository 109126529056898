const baseUrlAuth = "https://api.bousanm.com/api/";
const baseUrlAccount = "https://api.bousanm.com/api/clients/";
const baseUrlAccount1 = "https://api.bousanm.com/api/clients/comptes/";
const baseUrlCustomers = "https://api.bousanm.com/api/accounts/";
const baseUrlTimer = "https://api.bousanm.com/api/transactions/";
const baseUrlTransactions = "https://api.bousanm.com/api/transactions/";
const baseUrlTransactionsSol = "https://api.bousanm.com/api/sol/";
const BaseUrlPhoto = "https://api.bousanm.com";
const BaseUrlPhotoClient = "https://api.bousanm.com/media/";

// const baseUrlAuth = "http://127.0.0.1:8000/api/";
// const baseUrlAccount = "http://127.0.0.1:8000/api/clients/";
// const baseUrlAccount1 = "http://127.0.0.1:8000/api/clients/comptes/";
// const baseUrlCustomers = "http://127.0.0.1:8000/api/accounts/";
// const baseUrlTimer = "http://127.0.0.1:8000/api/transactions/";
// const baseUrlTransactions = "http://127.0.0.1:8000/api/transactions/";
// const baseUrlTransactionsSol = "http://127.0.0.1:8000/api/sol/";
// const BaseUrlPhoto = "http://127.0.0.1:8000";
// const BaseUrlPhotoClient = "http://127.0.0.1:8000/media/";

export {
  baseUrlAuth,
  baseUrlAccount,
  baseUrlAccount1,
  baseUrlCustomers,
  baseUrlTimer,
  baseUrlTransactions,
  baseUrlTransactionsSol,
  BaseUrlPhoto,
  BaseUrlPhotoClient,
};
