import React, { useState } from 'react';
import useReportService from '../../../services/reportService';
import { useHistory } from 'react-router-dom';
import Sidebar from '../../Include/Sidebar';
const swal = require("sweetalert2");

const ExcelReport = () => {
  const [month, setMonth] = useState('');
  const [year, setYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const reportService = useReportService();

  // Options pour les mois
  const monthOptions = [
    { value: '', label: 'Mois courant' },
    { value: '1', label: 'Janvier' },
    { value: '2', label: 'Février' },
    { value: '3', label: 'Mars' },
    { value: '4', label: 'Avril' },
    { value: '5', label: 'Mai' },
    { value: '6', label: 'Juin' },
    { value: '7', label: 'Juillet' },
    { value: '8', label: 'Août' },
    { value: '9', label: 'Septembre' },
    { value: '10', label: 'Octobre' },
    { value: '11', label: 'Novembre' },
    { value: '12', label: 'Décembre' }
  ];

  // Options pour les années (5 ans en arrière jusqu'à l'année courante)
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = 0; i <= 5; i++) {
    const yearValue = currentYear - i;
    yearOptions.push({ value: yearValue, label: yearValue.toString() });
  }

  // Fonction pour générer et télécharger le rapport
  const handleGenerateReport = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Appel au service pour générer le rapport
      const result = await reportService.generateSolAccountsReport(month, year);
      
      if (result.success) {
        swal.fire({
          title: "Rapport généré et téléchargé avec succès",
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        // toast.success('Rapport généré et téléchargé avec succès');
      } else {

        swal.fire({
          title: result.message,
          icon: "error",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error('Erreur lors de la génération du rapport:', error);

      swal.fire({
        title: "Une erreur est survenue lors de la génération du rapport",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  return (

    <>

    <Sidebar />
    
    <main className="app-main">
    
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Génération de Rapports Excel</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#" onClick={() => history.push('/dashboard')}>Accueil</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#" onClick={() => history.push('/reports')}>Rapports</a>
                </li>
                <li className="breadcrumb-item active">Rapports Excel</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Rapport des Comptes SOL</h3>
                </div>
                <div className="card-body">
                  <form onSubmit={handleGenerateReport}>
                    <div className="form-group">
                      <label htmlFor="month">Mois</label>
                      <select
                        id="month"
                        className="form-control"
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                      >
                        {monthOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      <small className="form-text text-muted">
                        Laissez vide pour utiliser le mois courant.
                      </small>
                    </div>

                    <div className="form-group">
                      <label htmlFor="year">Année</label>
                      <select
                        id="year"
                        className="form-control"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                      >
                        {yearOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                          Génération en cours...
                        </>
                      ) : (
                        <>
                          <i className="fas fa-file-excel mr-2"></i>
                          Générer et télécharger
                        </>
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Instructions</h3>
                </div>
                <div className="card-body">
                  <p>Ce formulaire vous permet de générer un rapport Excel pour les comptes SOL.</p>
                  <ul>
                    <li>Sélectionnez un mois spécifique ou laissez vide pour utiliser le mois courant.</li>
                    <li>Sélectionnez l'année pour laquelle vous souhaitez générer le rapport.</li>
                    <li>Cliquez sur "Générer et télécharger" pour obtenir le rapport au format Excel.</li>
                  </ul>
                  <p>Le rapport inclut une feuille pour chaque semaine du mois et une feuille pour le jour actuel.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </main>
    </>
  );
};

export default ExcelReport;
