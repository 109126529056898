// AppRoutes.js
import React from "react";
import Login from "../auth/Login";
import Dashboard from "../page/Dashboard";
import AddAcount from "../page/accounts/AddAcount";
import PrivateRoute from "../utils/PrivateRoute";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "../context/AuthContext";
import ListAcount from "../page/accounts/ListAcount";
import { AccountProvider } from "../context/AccountsContext";
import ViewsAccount from "../page/accounts/ViewsAccount";
import User from "../page/users/Users";
import UserProfile from "../auth/Profile";
import ForgotPassword from "../auth/ForgotPassword";
import ResetPassword from "../auth/ResetPassword";
import AddTransaction from "../page/transactions/AddTransaction";
import ListTransaction from "../page/transactions/ListTransaction";
import UserEdit from "../auth/UserEdit";

import { TransactionProvider } from "../context/TransactionsContext";
import Renewal from "../page/transactions/Renewal";
// import { TimerProvider } from "../context/TimerContext";
import NewAccount from "../page/accounts/NewAccount";
import WithdrawInterest from "../page/transactions/WithdrawInterest";
import Customer from "../page/mutuelle/Customer";
import TransactionsSol from "../page/mutuelle/TransactionsSol";
import { CustomerProvider } from "../context/CustomerContext";
import { TransactionSolProvider } from "../context/TransactionSolContext";
import CustomersMutuelle from "../page/mutuelle/CustomersMutuelle";
// import Register from "../auth/Register";
import TransactionsMutuelle from "../page/mutuelle/TransactionsMutuelle";
import CustomerUpdate from "../page/mutuelle/CustomerUpdate";
import ListTransactionlocal from "../page/transactions/ListTransactionlocal";
import AddTransactionlocal from "../page/transactions/AddTransactionlocal";
import ProfilSol from "../page/accounts/ProfilSol";
import AccountSearch from "../page/accounts/AccountSearch";
import { OnlineRegister } from "../page/accounts/OnlineRegister";
import NotFound from "../400/NotFound";
import ListAccountValidate from "../page/accounts/ListAccountValidate";
import HistoryTransactions from "../page/accounts/HistoryTransactions";
import { TimerProvider } from "../context/TimerContext";
import Paiement from "../page/transactions/Paiement";
import AddPay from "../page/transactions/AddPay";
import Transfert from "../page/accounts/Transfert";
import Privacy from "../utils/Privacy";
import Terms from "../utils/Terms";

import ExcelReport from "../page/transactions/ExcelReport";
import ReportPage from "../ReportPage";
import AddAgent from "../page/agents/AddAgent";
import ListAgent from "../page/agents/ListAgent";

function AppRoutes() {
  return (
    <body class="layout-fixed sidebar-expand-lg bg-body-tertiary">
      <div class="app-wrapper">
        <Router>
          <AuthProvider>
            <AccountProvider>
              <TransactionProvider>
                <TimerProvider>
                  <CustomerProvider>
                    <TransactionSolProvider>
                      <Switch>
                        <Route path="/" component={Login} exact />
                        <Route path="/login" component={Login} exact />
                        {/* <Route path="/prof" component={Profile} /> */}
                        <Route path="/register" component={OnlineRegister} />
                        <PrivateRoute path="/users" component={User} />
                        <PrivateRoute
                          path="/detail-user/:user_id"
                          component={UserEdit}
                        />
                        <PrivateRoute
                          path="/user-profile/:user_id"
                          component={UserProfile}
                        />
                        <PrivateRoute path="/add-renewal" component={Renewal} />
                        <PrivateRoute path="/dashboard" component={Dashboard} />
                        <PrivateRoute path="/List" component={ListAcount} />
                        <PrivateRoute
                          path="/validate-account"
                          component={ListAccountValidate}
                        />
                        <PrivateRoute
                          path="/views-account/:accounts_id"
                          component={ViewsAccount}
                        />
                        <PrivateRoute
                          path="/transaction"
                          component={ListTransaction}
                        />
                        <PrivateRoute path="/paiement" component={Paiement} />
                        <PrivateRoute path="/add-pay" component={AddPay} />
                        <PrivateRoute
                          path="/transactionlocal"
                          component={ListTransactionlocal}
                        />
                        <PrivateRoute
                          path="/add-transaction"
                          component={AddTransaction}
                        />
                        <PrivateRoute
                          path="/add-transactionlocal"
                          component={AddTransactionlocal}
                        />
                        <PrivateRoute
                          path="/excel-report"
                          component={ExcelReport}
                        />
                        <PrivateRoute
                          path="/add-account"
                          component={AddAcount}
                        />
                        <PrivateRoute
                          path="/account-search"
                          component={AccountSearch}
                        />
                        <PrivateRoute
                          path="/history"
                          component={HistoryTransactions}
                        />
                        <PrivateRoute path="/transfert" component={Transfert} />
                        <PrivateRoute
                          path="/excel-report"
                          component={ExcelReport}
                        />
                        <PrivateRoute path="/reports" component={ReportPage} />
                        <PrivateRoute path="/add-agent" component={AddAgent} />
                        <PrivateRoute
                          path="/list-agent"
                          component={ListAgent}
                        />
                        <PrivateRoute
                          path="/View-account"
                          component={ListAcount}
                        />
                        <PrivateRoute
                          path="/View-custommer/:accounts_id"
                          component={CustomerUpdate}
                        />
                        <PrivateRoute
                          path="/withdraw-interest"
                          component={WithdrawInterest}
                        />
                        <PrivateRoute
                          path="/new-account/:accounts_id"
                          component={NewAccount}
                        />
                        {/* <Route path="/profile" component={Profile} /> */}
                        <PrivateRoute path="/profile" component={ProfilSol} />
                        <PrivateRoute path="/customers" component={Customer} />
                        <PrivateRoute
                          path="/customers-mutuelle"
                          component={CustomersMutuelle}
                        />
                        <PrivateRoute
                          path="/sol-transactions"
                          component={TransactionsSol}
                        />
                        <PrivateRoute
                          path="/mutuelle-transactions"
                          component={TransactionsMutuelle}
                        />
                        <Route
                          path="/forgot_password"
                          component={ForgotPassword}
                          exact
                        />
                        <Route
                          path="/reset-password/:uidb64/:token"
                          component={ResetPassword}
                        />
                        {/* <Route path="/reset-password" component={ResetPassword} /> */}

                        {/* Route 404 */}
                        <Route path="*" component={NotFound} />
                      </Switch>
                    </TransactionSolProvider>
                  </CustomerProvider>
                </TimerProvider>
              </TransactionProvider>
            </AccountProvider>
          </AuthProvider>
        </Router>
      </div>
    </body>
  );
}

export default AppRoutes;
