import React, { useContext, useEffect, useState } from 'react';
import { TransactionsContext } from './context/TransactionsContext';
import { TransactionSolContext } from './context/TransactionSolContext';
import { Link } from 'react-router-dom';
import Sidebar from './Include/Sidebar';

const ReportDashboard = () => {
    return (
        <>

<Sidebar />

<main className="app-main">

        <div className="container mt-4">
            <div className="row">
                <div className="col-md-6 mb-4">
                    <div className="card">
                        <div className="card-header bg-primary text-white">
                            <h5 className="mb-0">Rapports Excel</h5>
                        </div>
                        <div className="card-body">
                            <p>Générez et téléchargez des rapports Excel pour les comptes SOL.</p>
                            <Link to="/excel-report" className="btn btn-primary">
                                <i className="fas fa-file-excel mr-2"></i> Générer un rapport
                            </Link>
                        </div>
                    </div>
                </div>
                
                {/* Emplacement pour d'autres types de rapports à l'avenir */}
                <div className="col-md-6 mb-4">
                    <div className="card">
                        <div className="card-header bg-secondary text-white">
                            <h5 className="mb-0">Autres rapports</h5>
                        </div>
                        <div className="card-body">
                            <p>D'autres types de rapports seront disponibles prochainement.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </main>
        </>
    );
};

export default ReportDashboard;
