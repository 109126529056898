/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const swal = require("sweetalert2");
export default function ResetPassword() {
  // Try getting parameters from both useParams and directly from the URL
  const params = useParams();
  const { uidb64, token } = params;
  const { resetPassword } = useContext(AuthContext);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [urlParams, setUrlParams] = useState({});

  // Add useEffect to log parameters and try to extract from URL if needed
  useEffect(() => {
    console.log("useParams result:", params);

    // Try to extract from URL if useParams fails
    const path = window.location.pathname;
    const segments = path.split("/");

    // Assuming URL format is /reset-password/:uidb64/:token/
    if (segments.length >= 4) {
      const extractedUidb64 = segments[2];
      const extractedToken = segments[3];

      console.log("Extracted from URL:", {
        uidb64: extractedUidb64,
        token: extractedToken,
      });

      setUrlParams({
        uidb64: extractedUidb64,
        token: extractedToken,
      });
    }
  }, [params]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Use params from useParams or fallback to extracted URL params
    const finalUidb64 = uidb64 || urlParams.uidb64;
    const finalToken = token || urlParams.token;

    console.log("Submitting with parameters:", {
      uidb64: finalUidb64,
      token: finalToken,
      newPassword,
      confirmPassword,
    });

    try {
      if (!finalUidb64 || !finalToken) {
        throw new Error("Missing reset password parameters");
      }

      await resetPassword(
        finalUidb64,
        finalToken,
        newPassword,
        confirmPassword
      );
    } catch (error) {
      console.error("Reset password error:", error);
      swal.fire({
        title: "Error",
        text: error.message || "Failed to reset password. Please try again.",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Bousanm | Reset Password";
  }, []);

  return (
    <section className="bg-light min-vh-100 d-flex flex-column">
      <div
        className="container-fluid bg-light py-2 d-flex justify-content-center align-items-center"
        id="navbar"
      >
        <div className="container text-center d-flex justify-content-between align-items-center">
          <div>
            <a href="mailto:zig.bousanm@gmail.com" className="text-muted small">
              <i className="fas fa-envelope text-primary me-1"></i>{" "}
              zig.bousanm@gmail.com
            </a>
          </div>
          <div>
            <a href="#" className="text-muted small me-1">
              <i className="fa fa-clock text-primary me-0"></i> 7/7 | 24/24
            </a>
          </div>
          <div>
            {" "}
            <a
              href="https://bousanm.com/"
              className="btn btn-primary btn-sm py-0"
            >
              <i className="fas fa-home"></i>
            </a>
          </div>
        </div>
      </div>

      <div className="container-fluid d-flex justify-content-center align-items-center flex-grow-1">
        <div className="container">
          <div
            className="text-center mx-auto  wow fadeInUp"
            style={{ maxWidth: "700px" }}
          >
            <h3 className=" text-primary">Réinitialisation</h3>
          </div>
          <div className="row g-5 row justify-content-center align-items-center">
            <div className="col-xl-4 wow fadeInRight" data-wow-delay="0.4s">
              <div className="card shadow-sm">
                <div className="card-body">
                  <div className="row justify-content-center align-items-center mb-4">
                    <img
                      src="/img/logo.png"
                      style={{ width: "90px" }}
                      alt="Logo"
                    />
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="row gy-4">
                      <div className="col-md-12">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Nouveau mot de passe"
                          onChange={(e) => setNewPassword(e.target.value)}
                          id="name"
                          required
                          name="password"
                        />
                      </div>
                      <div className="col-12">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Confirmer le mot de passe"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          id="pass"
                          required
                          name="password"
                        />
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          disabled={loading}
                          className="btn btn-primary w-100 text-center border-0"
                        >
                          {loading ? (
                            <>
                              <i className="fas fa-spinner fa-spin"></i>{" "}
                              Enregistrement en cours...
                            </>
                          ) : (
                            <> Enregistrer </>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-4 wow fadeInLeft" data-wow-delay="0.2s">
              <div className="contact-img d-flex justify-content-center">
                <div className="contact-img-inner">
                  <img
                    src="/img/reset-img.png"
                    className="img-fluid w-100"
                    alt="Image Contact"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
